import React, { useState, useEffect } from 'react';
import { VisorPDF } from 'ui-component';
import { Grid, Box, Button } from '@mui/material';

const ActPdf = ({ permiso, usuario, data, onClickFinalizar }) => {
    const finalizar = () => {
        onClickFinalizar({
            tipo: data?.tipo,
            idDetalleActividad: data?.idDetalleActividad,
            idColaborador: data?.idColaborador,
            idActividad: data?.idActividad,
            idTemario: data?.idTemario,
            idSesion: data?.idSesion,
            idCurso: data?.idCurso
        });
    };

    return (
        <Grid container spacing={2}>
            {/* <Grid item xs={12} style={{ backgroundColor: '#f5f5f5', marginLeft: '10px', paddingRight: '10px' }}></Grid> */}
            <Grid item xs={12}>
                <VisorPDF data={data?.pdf} isBase64={true} />
            </Grid>
            {(!data?.idCalificacionDetalle || data?.idCalificacionDetalle == null) && (
                <Grid item xs={12} display="flex" justifyContent="end">
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: '#003764', color: '#FFFFFF', borderRadius: '0px' }}
                        onClick={finalizar}
                    >
                        Terminado
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default ActPdf;
