import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, FormControl, FormControlLabel, Grid, Radio, TextField } from '@mui/material';

import InputCreateHtml from './InputCreateHtml';
import { useFormContext } from 'react-hook-form';
import { FormDropZone, GroupRadioButtonForm, ModalGeneral, TextFieldNumber, VisorPDF } from 'ui-component';
import { BigPlayButton, ControlBar, VolumeMenuButton, Player } from 'video-react';
import { colors } from '@mui/material';
import { Delete, CloudDownload, PictureAsPdf } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    helperTextError: {
        color: colors.red[500],
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    helperText: {
        color: 'black',
        marginLeft: '0px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    helperText2: {
        color: colors.grey[500],
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    }
}));

const FormDetalleActividad = ({ open, isAdd, data, ctl, isVer, ...props }) => {
    const classes = useStyles();
    const [modal, setModal] = useState({ open: false, titulo: '' });
    const {
        register,
        watch,
        formState: { errors },
        control,
        setValue,
        getValues,
        unregister
    } = useFormContext();

    const idTipoActividad = watch('idTipoActividad') === undefined ? data?.idTipoActividad : watch('idTipoActividad');
    const clickEliminarArchivoActivo = () => setValue('archivoActivo', null);
    const cerrarModal = () => setModal({ open: false });
    const abrirPDF = () => {
        setModal({
            open: true,
            formulario: VisorPDF,
            propsModal: {
                isBase64: true,
                data: data,
                title: 'PDF actividad' + data?.titulo
            }
        });
    };
    const descargarArchivo = () => {
        window.open(data?.linkVideo, '_blank', 'noreferrer');
    };
    const extensionesValidasDescargables = [
        'image/*',
        'video/*',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/xml',
        'text/xml',
        'audio/*'
    ];
    const extensionesNoValidas = [
        'application/x-msdownload',
        'application/javascript',
        'text/javascript',
        'application/x-msdos-program',
        'application/x-sh',
        'text/x-sh',
        'application/octet-stream',
        ''
    ];
    const dropZoneRules = {
        required: { value: true, message: 'Campo requerido' },
        validate: (value) => !extensionesNoValidas.includes(value[0]?.type) || 'Extensión no válida'
    };
    useEffect(() => {
        if (data) {
            register('idTipoActividad');
            setValue('idTipoActividad', data?.idTipoActividad);

            register('solicitarEvidencia');
            setValue('solicitarEvidencia', data?.solicitarEvidencia);
        } else {
            register('solicitarEvidencia');
            setValue('solicitarEvidencia', 0);
        }

        if (data && [2, 4, 5, 6].includes(Number(data?.idTipoActividad))) {
            register('archivoActivo');
            setValue('archivoActivo', data?.linkVideo);
        } else unregister('archivoActivo');
    }, [data]);
    return (
        <Grid container spacing={2}>
            {!isVer && (
                <>
                    <Grid item xs={6}>
                        <TextField
                            id="txt-tit"
                            label="Título *"
                            size="small"
                            fullWidth
                            {...register('titulo', {
                                required: { value: true, message: 'Campo requerido' },
                                validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                            })}
                            error={Boolean(errors.titulo)}
                            helperText={errors.titulo?.message}
                            defaultValue={data?.titulo}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="txt-tipo"
                            label="Tipo de material *"
                            size="small"
                            fullWidth
                            select
                            SelectProps={{ native: true }}
                            disabled={data?.idTipoActividad}
                            {...register('idTipoActividad', { required: { value: !data?.idTipoActividad, message: 'Campo requerido' } })}
                            error={Boolean(errors.idTipoActividad)}
                            helperText={errors.idTipoActividad?.message}
                            defaultValue={data?.idTipoActividad}
                            onChange={(event) => {
                                setValue('idTipoActividad', event.target.value);
                                setValue('archivo', []);
                            }}
                            multiline
                        >
                            <option />
                            <option value="1">Lectura</option>
                            <option value="2">Archivo</option>
                            <option value="3">Examen</option>
                            <option value="7">Encuesta</option>
                        </TextField>
                    </Grid>
                </>
            )}
            {[2, 4, 5, 6].includes(Number(idTipoActividad)) && (
                <>
                    <Grid item xs={6}>
                        <GroupRadioButtonForm
                            id="frm-btn-evi"
                            control={control}
                            name="solicitarEvidencia"
                            label="Solicitar Evidencia"
                            defaultValue={data?.solicitarEvidencia || '0'}
                            rules={{ required: { value: true, message: 'Campo requerido' } }}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="SI" />
                            <FormControlLabel value="0" control={<Radio />} label="NO" />
                        </GroupRadioButtonForm>
                    </Grid>
                </>
            )}
            {[3].includes(Number(idTipoActividad)) && (
                <>
                    <Grid item xs={4}>
                        <TextFieldNumber
                            id="txt-num-cal"
                            label="Calificacion aprobatoria"
                            name="calificacion"
                            defaultValue={data?.calificacion}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Campo requerido' },
                                value: (calificacion) => calificacion <= 100 || 'La calificacion debe estar en el rango 0 - 100'
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldNumber
                            id="txt-num-dia"
                            label="Días para liberar"
                            name="diasHabilitar"
                            defaultValue={data?.diasHabilitar}
                            suffix=" días"
                            control={control}
                            rules={{
                                required: { value: true, message: 'Campo requerido' },
                                value: (calificacion) => calificacion <= 0 || 'la cantidad mínima es 1'
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldNumber
                            id="txt-num-int"
                            label="Número de intentos"
                            name="numIntentos"
                            defaultValue={data?.numIntentos}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Campo requerido' },
                                value: (calificacion) => calificacion <= 0 || 'la cantidad mínima es 1'
                            }}
                        />
                    </Grid>
                </>
            )}
            {[7].includes(Number(idTipoActividad)) && (
                <>
                    <Grid item xs={6}>
                        <TextFieldNumber
                            id="txt-num-dia"
                            label="Días para liberar"
                            name="diasHabilitar"
                            defaultValue={data?.diasHabilitar}
                            suffix=" días"
                            control={control}
                            rules={{
                                required: { value: true, message: 'Campo requerido' },
                                value: (calificacion) => calificacion <= 0 || 'la cantidad mínima es 1'
                            }}
                        />
                    </Grid>
                </>
            )}
            {[2, 5].includes(Number(idTipoActividad)) &&
                (watch('archivoActivo') == null && [0, null, undefined].includes(isVer) ? (
                    <Grid item xs={12}>
                        <FormDropZone
                            id="drp-zn-arc"
                            control={control}
                            name="archivo"
                            rules={dropZoneRules}
                            acceptedFiles={extensionesValidasDescargables}
                        />
                        <br></br>
                        <helperText className={classes.helperTextError}>{errors?.archivo?.message}</helperText>
                    </Grid>
                ) : (
                    <Grid item xs={12} container>
                        {!isVer && (
                            <Grid item xs={2}>
                                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                    <Button id="btn-err" color="error" onClick={clickEliminarArchivoActivo}>
                                        <Delete />
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        )}
                        {idTipoActividad == 2 ? (
                            <Grid item xs={12}>
                                <Player src={data?.linkVideo}>
                                    <BigPlayButton position="center" />
                                    {/* <ControlBar autoHide className="my-class">
                                    <VolumeMenuButton />
                                    <VolumeMenuButton vertical />
                                    </ControlBar> */}
                                </Player>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <img src={data?.linkVideo} alt="imagen actividad" width="100%" height="500px" />
                            </Grid>
                        )}
                    </Grid>
                ))}
            {idTipoActividad == 1 && (
                <Grid item xs={12}>
                    <InputCreateHtml id="inp-cre-htm" control={control} name="lectura" label="Lectura" defaultValue={data?.lectura} />
                </Grid>
            )}
            {[4, 6].includes(Number(idTipoActividad)) && (
                <>
                    {watch('archivoActivo') == null && [0, null, undefined].includes(isVer) ? (
                        <Grid item xs={12}>
                            <FormDropZone
                                id="drp-zn-pdf"
                                control={control}
                                name="archivo"
                                rules={dropZoneRules}
                                acceptedFiles={idTipoActividad == 4 ? ['application/pdf'] : extensionesValidasDescargables}
                            />
                            <br></br>
                            <helperText className={classes.helperTextError}>{errors?.archivo?.message}</helperText>
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12}></Grid>
                            {!isVer && (
                                <Grid item xs={4}>
                                    <Button id="btn-del-arc" color="error" variant="contained" onClick={clickEliminarArchivoActivo}>
                                        <Delete />
                                        Eliminar archivo
                                    </Button>
                                </Grid>
                            )}
                            <Grid item xs={8}>
                                <Button
                                    id="btn-ver"
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    tabIndex={-1}
                                    startIcon={idTipoActividad == 4 ? <PictureAsPdf /> : <CloudDownload />}
                                    color="primary"
                                    onClick={idTipoActividad == 4 ? abrirPDF : descargarArchivo}
                                >
                                    {idTipoActividad == 4 ? 'Abrir PDF' : 'Ver archivo'}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
            <ModalGeneral open={modal.open} width="md" titulo={modal.titulo} OnCerrar={cerrarModal}>
                {modal.formulario && <modal.formulario {...modal.propsModal} />}
            </ModalGeneral>
        </Grid>
    );
};

export default FormDetalleActividad;
