import React, { useState, useEffect, useContext } from 'react';
import LayautContext from 'Context/ContextLayaut';
import { School, Group } from '@mui/icons-material';
import {
    Grid,
    Typography,
    Stack,
    Card,
    CardContent,
    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
    TextField,
    CardActions,
    Breadcrumbs,
    CardHeader
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm, Controller } from 'react-hook-form';
import PropTypes, { object } from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

import Services from 'utils/serviciosVentas';
import { ConsoleView } from 'react-device-detect';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        color: theme.palette.black,
        backgroundColor: theme.palette.white
    },
    cardContent: {
        color: theme.palette.black,
        backgroundColor: theme.palette.white
    },
    divider: {
        borderBlockColor: theme.palette.black[200]
    },
    errorCard: {
        backgroundColor: theme.palette.error.light
    },
    successCard: {
        backgroundColor: theme.palette.success.light
    }
}));

// Components
const RespuestaAbierta = (props) => {
    const { permiso, pregunta, control, getValues, selectResp, onlyShow } = props;

    return (
        <Controller
            control={control}
            name={`pregunta${pregunta.idPregunta}`}
            rules={{
                validate: () => ![undefined, ''].includes(getValues(`pregunta${pregunta.idPregunta}`)?.value),
                required: { value: true, message: 'Escriba una respuesta.' }
            }}
            render={({ field: { onChange } }) => (
                <TextField
                    label={onlyShow ? '' : 'Escribir...'}
                    defaultValue={selectResp?.comentario}
                    InputProps={{ readOnly: onlyShow }}
                    fullWidth
                    multiline
                    maxRows={4}
                    onChange={(event) =>
                        onChange({
                            idPregunta: pregunta.idPregunta,
                            idTipoPregunta: pregunta.idTipoPregunta,
                            value: event.target.value
                        })
                    }
                />
            )}
        />
    );
};
const RespuestaCerrada = (props) => {
    const { pregunta, control, getValues, selectResp, onlyShow } = props;
    const respuestaUsuario = selectResp
        ? {
              idPregunta: pregunta.idPregunta,
              idTipoPregunta: pregunta.idTipoPregunta,
              value: selectResp.idRespuesta
          }
        : null;
    return (
        <Controller
            control={control}
            name={`pregunta${pregunta.idPregunta}`}
            rules={{
                validate: () => ![undefined, ''].includes(getValues(`pregunta${pregunta.idPregunta}`)?.value),
                required: { value: true, message: 'Seleccione una respuesta.' }
            }}
            render={({ field: { onChange } }) => (
                <RadioGroup
                    onChange={(event) =>
                        onChange({
                            idPregunta: pregunta.idPregunta,
                            idTipoPregunta: pregunta.idTipoPregunta,
                            value: event.target.value
                        })
                    }
                >
                    {pregunta.respuestas.map((respuesta) => {
                        const propRadio = {
                            controlLabel: onlyShow ? { checked: Boolean(respuesta.idRespuestaUsuario) } : {},
                            radio: {}
                            // radio: onlyShow ? { color: respuesta.isCorrecta ? 'success' : 'error' } : {}
                        };
                        return (
                            <FormControlLabel
                                {...propRadio.controlLabel}
                                key={`Res${respuesta.idRespuesta}`}
                                value={respuesta.idRespuesta}
                                control={<Radio id={`respuesta${respuesta.idRespuesta}`} {...propRadio.radio} sx={{ p: '4px', m: 0 }} />}
                                label={<Typography variant="cardBody">{respuesta.nrespuesta}</Typography>}
                            />
                        );
                    })}
                </RadioGroup>
            )}
        />
    );
};
const Respuestas = (props) => {
    const { pregunta } = props;
    if (pregunta.idTipoPregunta == '1') {
        return <RespuestaCerrada pregunta={pregunta} {...props} />;
    }
    return <RespuestaAbierta pregunta={pregunta} {...props} />;
};
// .sort((pregunta) => Math.random() - 0.5)
const Preguntas = (props) =>
    props.preguntas?.map((pregunta, index) => {
        const selectResp = pregunta.respuestas.find((res) => res.idRespuestaUsuario);
        const headerOnlyShow = props.onlyShow && selectResp?.isCorrecta ? props.classes.successCard : props.classes.errorCard;
        const tipo = props.onlyShow ? headerOnlyShow : props.classes.cardHeader;
        const propCard = { className: tipo };
        return (
            <Grid key={pregunta.npregunta} container>
                <Grid item xs={12}>
                    <Card id={`cardpregunta` + pregunta?.idPregunta} sx={{ boxShadow: 2, m: 2 }}>
                        <CardContent {...propCard}>
                            <Typography variant="cardBody">
                                {index + 1}. {pregunta.npregunta}
                            </Typography>
                        </CardContent>
                        <Divider className={props.classes.divider} />
                        <CardContent className={props.classes.cardContent}>
                            <Respuestas pregunta={pregunta} selectResp={selectResp} {...props} />
                        </CardContent>
                        {props.errors[`pregunta${pregunta.idPregunta}`] && (
                            <>
                                <Divider className={props.classes.divider} />
                                <CardActions className={props.classes.errorCard} sx={{ backgroundColor: 'red', color: 'white' }}>
                                    {props.errors[`pregunta${pregunta.idPregunta}`].message}
                                </CardActions>
                            </>
                        )}
                    </Card>
                </Grid>
            </Grid>
        );
    });

const Examen = ({ permisos, usuario, data, breadcrumbs, onClickFinalizar, ...props }) => {
    console.log('data', data.infoGeneral);
    const classes = useStyles();
    const services = Services();
    const examen = data.examen || data.encuesta;
    const context = useContext(LayautContext);
    const {
        register,
        watch,
        getValues,
        setValue,
        formState: { errors },
        control,
        handleSubmit
    } = useForm();
    // Functions
    const finalizar = (preguntas) => {
        onClickFinalizar({
            tipo: data.tipo,
            idDetalleActividad: data.idDetalleActividad,
            idColaborador: data.idColaborador,
            idActividad: data.idActividad,
            idTemario: data.idTemario,
            idSesion: data.idSesion,
            idCurso: data.idCurso,
            preguntas: preguntas
        });
    };

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            context.mensaje({ open: true, status: 0, value: 'Necesitas contestar todas las preguntas para poder continuar' });
        }
    }, [errors]);

    if (!examen.length) {
        return <div>El examen aún no tiene preguntas</div>;
    }

    const propsPreguntas = {
        control: control,
        getValues: getValues,
        setValue: setValue,
        classes: classes,
        errors: errors,
        onlyShow: data.onlyShow
    };
    const revisionErrores = (data) => {
        const firstError = Object.keys(data).reduce((field, a) => {
            return !!data[field] ? field : a;
        }, null);
        const foundItem = document.getElementById(`card` + firstError);
        if (!foundItem) return;
        requestAnimationFrame(() => {
            foundItem.scrollIntoView({
                block: top ? 'center' : 'end',
                behavior: 'smooth'
            });
        });
    };
    return (
        <>
            {data.infoGeneral.bloqueoDias ? (
                <Card>
                    <CardHeader title="Cuestionario inhabilitado"></CardHeader>
                    <CardContent>
                        <Typography variant="body1">
                            Faltan {data.infoGeneral.numDiasBloqueado} días para que esta actividad este disponible.
                        </Typography>
                    </CardContent>
                </Card>
            ) : (
                <form onSubmit={handleSubmit(finalizar, revisionErrores)}>
                    <Grid container sx={{ mb: 3 }} spacing={2}>
                        <Grid item xs={12}>
                            <Stack>
                                <Scrollbars style={{ height: '55vh' }}>
                                    {examen?.map((material) => (
                                        <Preguntas key={material.titulo} preguntas={material.preguntas} {...propsPreguntas} />
                                    ))}
                                </Scrollbars>
                            </Stack>
                        </Grid>
                        {!data.onlyShow && (
                            <Grid item xs={12} display="flex" justifyContent="end">
                                {permisos?.finExa && (
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: '#003764', color: '#FFFFFF', borderRadius: '0px' }}
                                        type="submit"
                                    >
                                        Finalizar
                                    </Button>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </form>
            )}
        </>
    );
};

Examen.propTypes = {
    data: PropTypes.shape({
        examen: PropTypes.arrayOf(PropTypes.shape({ idDetalleActividad: PropTypes.string.isRequired, titulo: PropTypes.string.isRequired }))
    })
};

export default Examen;
