import React, { useEffect, useState } from 'react';
// material-ui
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowUp, StarBorder } from '@mui/icons-material';
import { Box, LinearProgress, Card, CardMedia, CardActions, Chip, Typography, Stack, Button, Grid, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Scrollbars } from 'react-custom-scrollbars';

// project imports
import Services from 'utils/serviciosVentas';
import CardCurso from './Cards/CardCurso';
import { width } from '@mui/system';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Inicio = (props) => {
    const { permiso, usuario, cursos, hasCursos, numCursos } = props;

    const services = Services();
    const theme = useTheme();

    return (
        <Grid
            container
            spacing={2}
            sx={{ overflowX: 'scroll', border: 'solid #dddddd 5px', borderRadius: '15px', backgroundColor: '#f9f9f9' }}
        >
            <Grid item xs={12}>
                <Chip style={{ backgroundColor: '#cabc93', color: '#FFFFFF' }} label={`Cursos activos: ${numCursos}`} size="medium" />
            </Grid>
            {hasCursos ? (
                <Grid item container spacing={1} xs={12} display="flex" alignItems="center" direction="row" wrap="nowrap">
                    {cursos
                        ?.filter((cur) => Number(cur.porcentajeCompletado) < 100)
                        .map((curso) => (
                            <Grid item key={`${curso.titulo}${curso.idCurso}`}>
                                <CardCurso curso={curso} {...props} />
                            </Grid>
                        ))}
                </Grid>
            ) : (
                <>
                    <br></br>
                    <Typography variant="h2" align="center">
                        No te encuentras inscrito a algún curso
                    </Typography>
                </>
            )}
        </Grid>

        // <Carousel centerMode centerSlidePercentage={30} showStatus={false} showIndicators={false} showThumbs={false}>
        //     {cursos?.map((curso) => (
        //         <CardCurso key={`${curso.titulo}${curso.idCurso}`} curso={curso} {...props} />
        //     ))}
        //     {!hasCursos && (
        //         <>
        //             <br></br>
        //             <Typography variant="h2" align="center">
        //                 No te encuentras inscrito a algún curso
        //             </Typography>
        //         </>
        //     )}
        // </Carousel>
    );
};

export default Inicio;
