import { Link } from 'react-router-dom';

// material-ui
import { Button, ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import Logo2 from 'ui-component/Logo2';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection2 = ({ handleLeftDrawerToggle }) => (
    <ButtonBase disableRipple component={Button} onClick={handleLeftDrawerToggle}>
        <Logo2 />
    </ButtonBase>
);

export default LogoSection2;
