import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Grid, Box, ButtonBase, Tooltip, Stack, Avatar, Menu, MenuItem, IconButton, Chip, Typography, Button, Paper } from '@mui/material';
import { ReactComponent as IconUser } from 'assets/images/icons/avatar-sin-foto.svg';

// project imports
import LogoSection from '../LogoSection';
import LogoSection2 from '../LogoSection2';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2, IconSettings, IconLogout } from '@tabler/icons';
import { ReactComponent as IconMenu } from 'assets/images/icons/menu.svg';
import { ReactComponent as IconEngrane } from 'assets/images/icons/engrane.svg';
import { ReactComponent as IconSalir } from 'assets/images/icons/salir.svg';
import LoginContext from 'Context/ContextLogin';
import { ExpandMore } from '@mui/icons-material';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    iconDefault: {
        width: '1.8rem',
        fill: theme.palette.primary.dark,
        '&:hover': { fill: theme.palette.dark.main }
    },
    iconSalir: {
        width: '4.5rem',
        fill: theme.palette.primary.dark,
        '&:hover': { fill: theme.palette.red.main }
    }
}));

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const classes = useStyles();
    const context = useContext(LoginContext);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const style = {
        chipUser: {
            display: 'flex',
            height: '60px',
            alignItems: 'center',
            borderRadius: '27px',
            transition: 'all .2s ease-in-out',
            borderColor: theme.palette.secondary.light,
            backgroundColor: theme.palette.secondary.light,
            '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.primary.main,
                background: `${theme.palette.primary.main}!important`,
                color: theme.palette.primary.light,
                '& svg': {
                    stroke: theme.palette.primary.light
                }
            },
            '& .MuiChip-label': {
                lineHeight: 0
            }
        },
        infoUser: theme.typography.title
    };

    const handleLogout = async () => {
        context.cerrarLogin();
    };

    const handleListItemClick = (event, index, route = '') => {
        if (route && route !== '') {
            navigate(route);
        }
    };

    const IconTop = (props) => {
        const { id, icon, onClick, tooltip } = props;
        return (
            <Tooltip title={tooltip} disableInteractive>
                <ButtonBase id={id} sx={{ borderRadius: '12px', overflow: 'hidden' }} onClick={onClick}>
                    {icon}
                </ButtonBase>
            </Tooltip>
        );
    };

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    display: 'flex'
                    // [theme.breakpoints.down('md')]: {
                    //     width: 'auto'
                    // }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'block', md: 'block' }, flexGrow: 1 }}>
                    {context?.login?.usuario?.idColaborador ? (
                        <LogoSection2 handleLeftDrawerToggle={handleLeftDrawerToggle} />
                    ) : (
                        <LogoSection />
                    )}
                </Box>
            </Box>

            {context?.login?.usuario?.idColaborador ? (
                <Grid
                    container
                    spacing={2}
                    sx={{
                        display: 'flex',
                        justifyContent: 'right'
                        // [theme.breakpoints.down('md')]: {
                        //     width: 'auto'
                        // }
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                            // [theme.breakpoints.down('md')]: {
                            //     width: 'auto'
                            // }
                        }}
                    >
                        <Paper
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', color: '#b6a268' }}
                        >
                            <Avatar
                                sx={{
                                    ...theme.typography.mediumAvatar,
                                    // margin: '8px 0 8px 8px !important',
                                    cursor: 'pointer',
                                    width: 50,
                                    height: 50
                                }}
                                aria-haspopup="true"
                                color="inherit"
                            >
                                {context?.login?.usuario?.foto ? (
                                    <img alt="fotoPerfil" src={context?.login?.usuario?.foto} width={50} height={50} />
                                ) : (
                                    <IconUser />
                                )}
                            </Avatar>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#b6a268' }}>
                                <Stack>
                                    <Stack alignItems="left">
                                        <Typography variant="title" sx={{ color: '#b6a268', padding: '5px' }}>
                                            {`${context.login?.usuario?.nombre}`}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack>
                                    {/* <Button  aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                            Open Menu
                                        </Button> */}
                                    <IconButton
                                        sx={{ color: '#b6a268' }}
                                        variant="contained"
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        component="span"
                                    >
                                        <ExpandMore />
                                    </IconButton>
                                    <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                                        <MenuItem
                                            onClick={(event) => {
                                                handleClose();
                                                handleListItemClick(event, 0, '/perfil');
                                            }}
                                        >
                                            Mi Perfil
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleClose();
                                                handleLogout();
                                            }}
                                        >
                                            Cerrar Sesión
                                        </MenuItem>
                                    </Menu>
                                </Stack>
                            </div>
                        </Paper>

                        {/* <Chip
                            id="btnPerfilUsuario"
                            sx={style.chipUser}
                            icon={
                                <Avatar
                                    sx={{
                                        ...theme.typography.mediumAvatar,
                                        // margin: '8px 0 8px 8px !important',
                                        cursor: 'pointer',
                                        width: 50,
                                        height: 50
                                    }}
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <IconUser />
                                </Avatar>
                            }
                            label={
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Stack>
                                        <Stack alignItems="left">
                                            <Typography variant="title">{`${context.login?.usuario?.nombre}`}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack>
                                        {/* <Button  aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                            Open Menu
                                        </Button> 
                                        <IconButton
                                            color="secondary"
                                            variant="contained"
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                            component="span"
                                        >
                                            <ExpandMore />
                                        </IconButton>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem
                                                onClick={(event) => {
                                                    handleClose();
                                                    handleListItemClick(event, 0, '/perfil');
                                                }}
                                            >
                                                Mi Perfil
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    handleClose();
                                                    handleLogout();
                                                }}
                                            >
                                                Cerrar Sesión
                                            </MenuItem>
                                        </Menu>
                                    </Stack>
                                </div>
                            }
                            variant="outlined"
                            aria-haspopup="true"
                            color="primary"
                            size="medium"
                        /> */}
                    </Grid>
                </Grid>
            ) : (
                <Grid
                    container
                    spacing={2}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                        // [theme.breakpoints.down('md')]: {
                        //     width: 'auto'
                        // }
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        md={8}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around'
                            // [theme.breakpoints.down('md')]: {
                            //     width: 'auto'
                            // }
                        }}
                    >
                        <ProfileSection />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around'
                            // [theme.breakpoints.down('md')]: {
                            //     width: 'auto'
                            // }
                        }}
                    >
                        <IconTop
                            id="btnMenuNavBar"
                            icon={<IconMenu stroke={1.5} className={classes.iconDefault} />}
                            tooltip="Esconder menú"
                            onClick={handleLeftDrawerToggle}
                        />
                        <IconTop
                            id="btnConfigurarPerfilNavBar"
                            icon={<IconEngrane stroke={1.5} className={classes.iconDefault} />}
                            tooltip="Configuración de perfil"
                            onClick={(event) => handleListItemClick(event, 0, '/perfil')}
                        />
                        <IconTop
                            id="btnCerrarSesionNavBar"
                            icon={<IconSalir stroke={1.5} className={classes.iconSalir} />}
                            tooltip="Cerrar sesión"
                            onClick={handleLogout}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
