import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Card, CardContent, CardHeader, Collapse, Divider, Grid, IconButton, Typography } from '@mui/material';
import { ChevronRight, KeyboardArrowDown, ExpandLess, ExpandMore } from '@mui/icons-material';
// ==============================|| CUSTOM SUB CARD ||============================== //

const SubCardCollapase = forwardRef(
    ({ children, openCollapse, content, contentClass, darkTitle, secondary, sx = {}, contentSX = {}, title, ...others }, ref) => {
        const theme = useTheme();
        const [open, setOpen] = useState(false);
        const abrirContent = () => setOpen(!open);

        return (
            <Card
                ref={ref}
                sx={{
                    border: 'none',
                    // borderColor: theme.palette.primary.light,
                    // ':hover': {
                    //     boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)'
                    // },
                    ...sx
                }}
                {...others}
            >
                {/* card header and action */}
                {!darkTitle && title && (
                    <CardHeader
                        title={
                            <Button
                                fullWidth
                                variant="contained"
                                size="small"
                                endIcon={open ? <KeyboardArrowDown /> : <ChevronRight />}
                                onClick={abrirContent}
                                sx={{ backgroundColor: '#cabc94', color: '#FFFFFF', borderRadius: '5px' }}
                            >
                                <Typography variant="menuTitle" sx={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '20px' }}>
                                    {title}
                                </Typography>
                            </Button>
                        }
                        titleTypographyProps={{ textAlign: 'center', color: '#FFFFFF' }}
                        action={secondary}
                    />
                )}
                {darkTitle && title && (
                    <CardHeader sx={{ p: 2.5 }} title={<Typography variant="h4">{title}</Typography>} action={secondary} />
                )}

                {/* content & header divider */}
                {/* {title && (
                    <Divider
                        sx={{
                            opacity: 1,
                            borderColor: theme.palette.primary.light
                        }}
                    />
                )} */}

                {/* card content */}
                {content && (
                    <Collapse in={open}>
                        <CardContent sx={{ p: 1, ...contentSX }} className={contentClass || ''}>
                            {children}
                        </CardContent>
                    </Collapse>
                )}
                {!content && children}
            </Card>
        );
    }
);

SubCardCollapase.propTypes = {
    children: PropTypes.node,
    openCollapse: PropTypes.bool,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    sx: PropTypes.object,
    contentSX: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

SubCardCollapase.defaultProps = {
    openCollapse: false,
    content: true
};

export default SubCardCollapase;
