import React, { useEffect, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { ChevronRight } from '@mui/icons-material';
import { ReactComponent as IconCurso } from 'assets/images/icons/ga-cursos.svg';
import { Grid, Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, Typography, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BigPlayButton, ControlBar, VolumeMenuButton, Player } from 'video-react';

// project imports
import Services from 'utils/serviciosVentas';
import { gridSpacing } from 'store/constant';
import SubCardCollapase from 'ui-component/cards/SubCardCollapase';
import Ranking from 'views/Dash/Components/Ranking';
import Cursos from './Components/Cursos';
import Banner from './Components/Banner';
import Contacto from './ComponentsHomeVentas/Contacto';
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    listStatus: {
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
    listItem: {
        paddingTop: '10px'
    }
}));

const ListItemAvance = (props) => {
    const { title, value } = props;
    const theme = useTheme();
    return (
        <>
            <ListItem className={props.classes.listItem}>
                <ListItemIcon>
                    <ChevronRight />
                </ListItemIcon>
                <ListItemIcon>
                    <IconCurso style={{ ...theme.typography.iconLg, color: '#000' }} />
                </ListItemIcon>
                <Typography variant="menuTitle">{title}</Typography>
                <ListItemSecondaryAction>
                    <Typography variant="menuTitle">{value}</Typography>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
        </>
    );
};

const Inicio = (props) => {
    const { usuario, permiso, cursos, ranking, info, videoData, hasCursos } = props;
    // console.log('usuario home', usuario);

    const classes = useStyles();
    const services = Services();
    const theme = useTheme();

    const [banners, setBanners] = useState([]);
    const [video, setVideo] = useState({});
    const [numCursos, setNum] = useState(0);

    const cursosTotal = cursos?.length;
    const cursosCompletados = cursos?.filter((curso) => curso.porcentajeCompletado >= 100).length;
    const cursosConCalif = cursos?.filter((curso) => curso?.calificacion != 0).length;
    const cursosPromedio = cursosConCalif ? cursos?.reduce((prev, curso) => prev + curso?.calificacion, 0) / cursosConCalif : 0;

    useEffect(() => {
        services.getBannersAndVideos((res) => {
            setBanners(res.banners);
            setVideo(res.video);
        });
    }, []);

    useEffect(() => {
        if (cursos && cursos.length > 0) {
            const arr = cursos.filter((cur) => Number(cur.porcentajeCompletado) < 100);

            if (arr && arr.length > 0) {
                setNum(arr.length);
            }
        }
    }, [cursos]);

    return (
        <MainCard title={<Typography variant="title">Cursos</Typography>}>
            <Grid container sx={{ pl: 4 }} spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Banner banners={banners} videoData={videoData} />
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    {/* <List className={classes.listStatus}>
                            <ListItemAvance title="Cursos" value={cursosTotal} classes={classes} />
                            <ListItemAvance title="Completados" value={cursosCompletados} classes={classes} />
                            {usuario.tipo_contrato && usuario.tipo_contrato != null && ['5', '4'].includes(usuario.tipo_contrato) && (
                                <ListItemAvance title="Posición" value={ranking?.posicion} classes={classes} />
                            )}
                            {usuario.tipo_contrato && usuario.tipo_contrato != null && ['5', '4'].includes(usuario.tipo_contrato) && (
                                <ListItemAvance title="Promedio" value={`${cursosPromedio?.toFixed(2)}%`} icon={<></>} classes={classes} />
                            )}
                        </List> */}
                    <Cursos cursos={cursos} hasCursos={hasCursos} numCursos={numCursos} {...props} />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Player playsInline src={videoData?.linkDrop} autoPlay>
                                <BigPlayButton position="center" />
                                <ControlBar autoHide className="my-class">
                                    <VolumeMenuButton vertical />
                                </ControlBar>
                            </Player>
                        </Grid>
                        {usuario.tipo_contrato && usuario.tipo_contrato != null && ['5', '4'].includes(usuario.tipo_contrato) && (
                            <Grid item xs={12}>
                                <SubCardCollapase title="Ranking">
                                    <Ranking colaboradores={ranking?.topTen} />
                                </SubCardCollapase>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <SubCardCollapase openCollapse title="Contacto">
                                <Contacto infoCotac={info} />
                            </SubCardCollapase>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default Inicio;
