import React, { useState, useEffect, useContext } from 'react';
import { AddBox, Reorder } from '@mui/icons-material';

import ModalAddEditUser from './components/ModalAddEditUser';

import { ModalGeneral, Tabla } from 'ui-component';
import headers from './components/headers';
import LayautContext from 'Context/ContextLayaut';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import { ServiciosCurso, ModalCostosGrupo } from './components';
import FormSesion from './components/FormSesion';
import FormOrdenar from './components/FormOrdenar';
import FormDependenciaSesion from './components/FormDependenciaSesion';

const Sesion = ({ permisos, curso, onCambioNivel, esTrainers }) => {
    //
    const context = useContext(LayautContext);
    const serviciosCursos = ServiciosCurso();

    const [sesion, setSesion] = useState([]);
    const [ctl, setCtl] = useState({ estatusCurso: [] });
    const [confirm, setConfirm] = useState({ open: false });
    const [modal, setModal] = useState({ open: false, titulo: '' });
    // Handle
    const actualizarData = (nuevaCurso = []) => {
        const datausuario = sesion.map((oc) => oc.idSesion);
        nuevaCurso.forEach((sesionI) => {
            const indice = datausuario.indexOf(sesionI.idSesion);
            if (indice >= 0) sesion.splice(indice, 1, sesionI);
            else sesion.splice(0, 0, sesionI);
        });
        setSesion([...sesion]);
    };
    const abrirEditarCurso = (row) => {
        row = {
            ...row,
            estatusCurso: {
                idEstatus: row.idSesionEstatus,
                nCursoEstatus: row.nCursoEstatus
            },
            extemporaneo: row.extemporaneo === 'Si' ? '1' : '0',
            interno: row.interno === 'Si' ? '1' : '0',
            puesto: row.npuesto ? { idpuesto: row.idpuesto, npuesto: row.npuesto } : null
        };
        context.formulario.abrir({
            open: true,
            titulo: `Editar la sesion ${row.titulo}`,
            propsForm: { data: row, ctl: ctl },
            formulario: FormSesion,
            aceptar: (dataForm) => {
                serviciosCursos.updateSesion(
                    (res) => {
                        actualizarData(res.sesion);
                        context.formulario.cerrar();
                    },
                    { idSesion: row.idSesion, ...dataForm, idCurso: curso.idCurso }
                );
            }
        });
    };
    const abrirNuevoCurso = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: 'Nueva sesión',
            propsForm: { data: null, ctl: ctl },
            formulario: FormSesion,
            aceptar: (dataForm) => {
                if (esTrainers) {
                    serviciosCursos.insertSesionTrainer(
                        (res) => {
                            actualizarData(res.sesion);
                            context.formulario.cerrar();
                        },
                        { ...dataForm, idCurso: curso.idCurso }
                    );
                } else {
                    serviciosCursos.insertSession(
                        (res) => {
                            actualizarData(res.sesion);
                            context.formulario.cerrar();
                        },
                        { ...dataForm, idCurso: curso.idCurso }
                    );
                }
            }
        });
    };
    const abrirConfirmarCambio = (sesion) => (e) => {
        let texto = `¿Estás seguro de volver a activar la sesión ${sesion.titulo}?`;
        let status = 1;
        if (sesion.estatus === '1') {
            texto = `¿Estás seguro de desactivar la sesión ${sesion.titulo}, una sesión desactivada no se tomará en cuenta para calificar al colaborador?`;
            status = 0;
        }
        setConfirm({
            open: true,
            text: texto,
            aceptar: () =>
                serviciosCursos.cambiarEstatusSesionCurso(
                    (nuevaData) => {
                        actualizarData(nuevaData.sesion);
                        setConfirm({ open: false });
                    },
                    { idSesion: sesion.idSesion, estatus: status, idCurso: curso.idCurso }
                )
        });
    };
    const abrirModalCurso = (sesion) => {
        serviciosCursos.getCursoCosto(
            (res) =>
                setModal({
                    open: true,
                    titulo: `Costos del sesion :${sesion.titulo}`,
                    formulario: ModalCostosGrupo,
                    sesion: { ...sesion, costo: res.sesionCosto }
                }),
            { idSesion: sesion.idSesion }
        );
    };
    const cerrarModalCurso = () => setModal({ ...sesion, open: false });

    const abrirOrdenar = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: 'Ordenar Sesiones',
            propsForm: { data: { dataOrden: sesion }, ctl: ctl },
            formulario: FormOrdenar,
            aceptar: (dataForm) => {
                if (dataForm.dataOrdenada == undefined) return context.mensaje({ open: true, value: 'No se ha hacho ningun orden' });
                serviciosCursos.updateOrdenSesion(
                    (res) => {
                        setSesion(res.sesion);
                        context.formulario.cerrar();
                    },
                    { ...dataForm, idCurso: curso.idCurso }
                );
                return true;
            }
        });
    };
    const guardarDependencia = (dependenciaSelected, regSelected) => (event) => {
        const dataSend = {
            dataSelected: dependenciaSelected,
            idCurso: curso.idCurso,
            idSesion: regSelected.idSesion
        };
        serviciosCursos.guardarDependenciaSesion((res) => {
            setSesion(res.sesion);
            cerrarModalCurso();
        }, dataSend);
    };
    const openFormDependencia = (selected) => {
        setModal({
            open: true,
            titulo: 'Agregar dependencias',
            formulario: FormDependenciaSesion,
            propsModal: {
                data: {
                    selected: selected,
                    allData: sesion.filter((ses) => ses.idSesion != selected.idSesion),
                    columnKey: 'idSesion'
                },
                headers: headers(null, { estatusCurso: null }, false).sesion,
                guardarDependencia: guardarDependencia,
                cerrar: cerrarModalCurso
            }
        });
    };

    // Config
    const acciones = [
        { label: 'Nueva sesión', icon: <AddBox />, click: abrirNuevoCurso, disabled: !permisos.modificar },
        { label: 'Ordenar actividades', icon: <Reorder />, click: abrirOrdenar, disabled: !permisos.modificar }
    ];

    const accionesFila = [
        { label: 'Editar', onClick: abrirEditarCurso, enabled: (row) => row.estatus == '1' && permisos.modificar },
        { label: 'Dependencia', onClick: openFormDependencia, enabled: (row) => row.estatus == '1' && permisos.modificar },
        {
            label: esTrainers ? 'Actividades' : 'Temario',
            onClick: (sesionSeleccionado) => onCambioNivel(sesionSeleccionado, sesion),
            enabled: true
        }
    ];
    useEffect(() => {
        if (curso)
            serviciosCursos.getsesionCurso(
                (ct) => {
                    setSesion(ct.sesion || []);
                },
                { idCurso: curso.idCurso }
            );
        else setSesion([]);
    }, [curso]);
    return (
        <>
            <Tabla
                rows={sesion}
                columns={headers(abrirConfirmarCambio, ctl, permisos).sesion}
                accionesTabla={acciones}
                accionesFila={accionesFila}
                columnKey="idSesion"
            />
            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
            <ModalGeneral open={modal.open} width="md" titulo={modal.titulo} OnCerrar={cerrarModalCurso}>
                {/* <ModalCostosGrupo sesion={modal.sesion} /> */}
                {modal.formulario && <modal.formulario {...modal.propsModal} />}
            </ModalGeneral>
        </>
    );
};

export default Sesion;
