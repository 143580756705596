import React, { Children, useContext, useState } from 'react';
import LayautContext from 'Context/ContextLayaut';
import {
    Collapse,
    colors,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    IconButton,
    Tooltip,
    Button,
    List,
    ListItemButton,
    ListItemText,
    Snackbar,
    Alert
} from '@mui/material';
import { RestorePage, CloudDownload, ChevronRight, ExpandMore } from '@mui/icons-material';
import { makeStyles, styled } from '@mui/styles';
import ServiciosCalificacion from './ServiciosCalificacion';
import FormEditCalificacion from './FormEditCalificacion';
import Link from '@mui/material/Link';
import { Scrollbars } from 'react-custom-scrollbars';
// import ModalFormulario from 'ui-component';
import OpcRegistro from './OpcRegistro';
import EstructuraExamen from './EstructuraExamen';
import Calificaciones from '..';

const Item = styled(Typography)(({ theme }) => ({
    ...theme.typography.body1,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));
const useStyles = makeStyles((theme) => ({
    respuestaCorrecta: {
        background: theme.palette.success.main,
        border: 0,
        borderRadius: 3,
        color: 'white'
    },
    respuestaSeleccionada: {
        border: `3px solid ${theme.palette.info.main}`
    },
    tree: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '6px 10px 0px 10px',
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: theme.palette.grey[400],
            color: 'var(--tree-view-color)'
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent'
        }
    },
    nestedCC: {
        paddingLeft: theme.spacing(4)
    },
    nestedEC: {
        paddingLeft: theme.spacing(8)
    },
    nestedAc: {
        paddingLeft: theme.spacing(12)
    },
    nestedAcTed: {
        paddingLeft: theme.spacing(16)
    }
}));
const columns = [
    { label: 'Pregunta', name: 'npregunta', filtrar: true },
    {
        label: 'Estatus',
        name: 'isnCorrecta',
        type: { opcion: 'etiqueta' },
        style: (row) => ({
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: row.isCorrecta == 1 ? colors.green[500] : colors.red[500]
        }),
        filtrar: {
            id: 'isCorrecta',
            value: 'isnCorrecta',
            ctl: [
                { isCorrecta: '1', isnCorrecta: 'Correcta' },
                { isCorrecta: '0', isnCorrecta: 'Incorrecta' }
            ]
        }
    }
];

// const ListaCursos = ({ data }) => {
//     const classes = useStyles();
//     const [esAbierto, setEsAbierto] = useState(true);
//     const [arregloMostrar, setArregloMostrar] = useState([]);

//     const handleChange = () => {
//         setEsAbierto((prev) => !prev);
//     };

//     const isObject = (x) => typeof x === 'object' && !Array.isArray(x) && x !== null;

//     if (data.idDetalleActividad) {
//         return <li>{data.titulo}</li>;
//     } else {
//         if (isObject(data)) {
//             if (data['sesiones']) {
//                 setArregloMostrar(data['sesiones']);
//             } else if (data['temario']) {
//                 setArregloMostrar(data['temario']);
//             } else if (data['activadad']) {
//                 setArregloMostrar(data['activadad']);
//             } else if (data['detalleActividad']) {
//                 setArregloMostrar(data['detalleActividad']);
//             }
//         } else {
//             setArregloMostrar(data);
//         }

//         console.log('arregloMostrar', arregloMostrar);
//         return (
//             <List className={classes.tree}>
//                 {data.map((value, key) => (
//                     <React.Fragment key={key}>
//                         <ListItemButton id="btn-curso" onClick={handleChange}>
//                             <IconButton
//                                 aria-label="more"
//                                 aria-controls="long-menu"
//                                 aria-haspopup="true"
//                                 //onClick={cambiarOpenP()}
//                             >
//                                 {esAbierto == true ? <ExpandMore /> : <ChevronRight />}
//                             </IconButton>
//                             <ListItemText primary={value?.titulo} />
//                             {/* <Tooltip>
//                                 <p>{curso?.calificacion}</p>
//                             </Tooltip> */}
//                         </ListItemButton>
//                         <Collapse in={esAbierto}>
//                             <ListaCursos data={value} />
//                         </Collapse>
//                     </React.Fragment>
//                 ))}
//             </List>
//         );
//     }
// };

export default function CursosCalificacionTree({ colaborador, esVistaTrainer }) {
    const classes = useStyles();
    const context = useContext(LayautContext);
    const serviciosCalificacion = ServiciosCalificacion();
    const [mensaje, setMensaje] = useState({
        open: false,
        status: 1,
        value: ''
    });
    const cerrarMensaje = (event) => setMensaje({ ...mensaje, open: false });

    const [calificaciones, setCalificaciones] = useState(colaborador.calificaciones);
    const [intentos, setIntentos] = useState([]);
    const [restaurados, setRestaurados] = useState([]);
    const [examenSeleccionado, setExamenSeleccionado] = useState(null);

    const [open, setOpen] = useState(null);
    const [openCc, setOpenCc] = useState(null);
    const [openEC, setOpenEC] = useState(null);
    const [openAc, setOpenAc] = useState(null);
    const [openAcTed, setOpenAcTed] = useState(null);
    // const array = anchorEl.row?.option != 'actividad'? accionesObra.filter(accion => accion.label == 'Historial'):accionesObra;

    const cambiarOpenP = (id) => {
        if (id === open) setOpen(null);
        else setOpen(id);
    };
    const cambiarOpenCc = (id) => {
        if (id === openCc) setOpenCc(null);
        else setOpenCc(id);
    };
    const cambiarOpenEC = (id) => {
        if (id === openEC) setOpenEC(null);
        else setOpenEC(id);
    };
    const cambiarOpenAc = (id) => {
        if (id === openAc) setOpenAc(null);
        else setOpenAc(id);
    };
    const cambiarOpenAcTed = (id) => {
        if (id === openAcTed) setOpenAcTed(null);
        else setOpenAcTed(id);
    };

    const abrirExamen = (detalleActividad) => {
        console.log('abrirExamen', detalleActividad);
        serviciosCalificacion.getExamen(
            (data) => {
                const intentosRever = data.intentos.reverse();
                const restauradosRever = intentosRever;

                setExamenSeleccionado({ ...data?.examen, idDetalleActividad: detalleActividad.idDetalleActividad });
                setIntentos(intentosRever, detalleActividad.idDetalleActividad);
                setRestaurados(restauradosRever, detalleActividad.idDetalleActividad);
            },
            {
                idDetalleActividad: detalleActividad.idDetalleActividad,
                idColaborador: colaborador.idcolaborador
            }
        );
    };
    // const cerrarExamen = () => {
    //     if (examenSeleccionado) setExamenSeleccionado(null);
    // };

    const cerrarExamen = (dato, accion) => (e) => {
        if (examenSeleccionado) setExamenSeleccionado(null);

        switch (dato) {
            case 'curso':
                cambiarOpenP(accion);
                break;
            case 'sesion':
                cambiarOpenCc(accion);
                break;
            case 'temario':
                cambiarOpenEC(accion);
                break;
            case 'actividad':
                cambiarOpenAc(accion);
                break;
            case 'examen':
                abrirExamen(accion);
            default:
                break;
        }
    };

    const restaurarExamen = () => {
        context.modal.confirmar({
            open: true,
            title: 'Confirmación.',
            text: 'Al resetear el examen, el colaborador tendra la oportunidad de repetir el examen.',
            aceptar: () => {
                context.modal.cerrar();
                serviciosCalificacion.restaurarExamen(
                    (data) => {
                        setCalificaciones(data.calificaciones);
                    },
                    {
                        idDetalleActividad: examenSeleccionado.idDetalleActividad,
                        idColaborador: colaborador.idcolaborador
                    }
                );
            }
        });
    };

    const editarCalificacion = (idColaborador, detalleActividad) => {
        context.formulario.abrir({
            open: true,
            titulo: 'Editar calificación',
            width: 'xs',
            propsForm: { data: detalleActividad },
            formulario: FormEditCalificacion,
            aceptar: (dataForm) => {
                serviciosCalificacion.editarCalificacion(
                    (res) => {
                        setCalificaciones(res.calificacionesTrainer);
                        context.formulario.cerrar();
                    },
                    {
                        idDetalleActividad: detalleActividad.idDetalleActividad,
                        idColaborador,
                        calificacion: dataForm.calificacion,
                        getSoloEntregables: esVistaTrainer ? true : false
                    }
                );
            }
        });
    };

    const getRespuestaActividad = (detalleActividad) => {
        console.log('getRespuesta', detalleActividad);
        serviciosCalificacion.getRespuestaActividad(
            (res) => {
                if (res.comentario) {
                    window.open(res.comentario, '_blank', 'noreferrer');
                } else setMensaje({ open: true, status: -1, value: 'No se pudo obtener el material' });
            },
            { idDetalleActividad: detalleActividad.idDetalleActividad, idColaborador: colaborador.idColaborador }
        );
    };

    const califActividad = (actividad) => {
        const calif = actividad?.detalleActividad?.reduce((prev, next) => prev + next?.calificacion, 0);
        return calif.toFixed(2);
    };

    const califTemario = (temario) => {
        const dividir = temario?.activadad?.length;
        const calif =
            temario?.activadad?.map((actividad) => parseFloat(califActividad(actividad))).reduce((prev, next) => prev + next, 0) / dividir;
        return calif.toFixed(2);
    };

    const califSesion = (sesion) => {
        const dividir = sesion?.temario?.length;
        const calif = sesion?.temario?.map((temario) => parseFloat(califTemario(temario))).reduce((prev, next) => prev + next, 0) / dividir;
        return calif.toFixed(2);
    };

    const califCurso = (curso) => {
        const dividir = curso?.sesiones?.length;
        const calif = curso?.sesiones?.map((sesion) => parseFloat(califSesion(sesion))).reduce((prev, next) => prev + next, 0) / dividir;
        return calif.toFixed(2);
    };

    const TootipEditar = (props) => {
        const { isEditado, usuario, fechaCreacion } = props;
        return isEditado ? (
            <div>
                <Typography align="center" variant="subtitle2">
                    {`Editado por: ${usuario || '-'}`}
                </Typography>
                <Typography align="center" variant="subtitle2">
                    {`Fecha: ${fechaCreacion || '-'}`}
                </Typography>
            </div>
        ) : (
            'Editar'
        );
    };

    const handleinput = (intentos, idColaborador, detalleActividad) => {
        context.formulario.abrir({
            open: true,
            titulo: 'Resultado de examen',
            width: 'md',
            propsForm: { data: intentos, columns: columns },
            formulario: OpcRegistro,
            isVer: true
        });
    };

    const acciones = [
        {
            label: (
                <div>
                    <Typography sx={{ width: '100%', color: '#fff' }} align="center" variant="cardTitle">
                        Intentos
                    </Typography>
                    <Table aria-label="simple table">
                        <Scrollbars style={{ height: 250, width: 300 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: '#fff' }}>#</TableCell>
                                    <TableCell sx={{ color: '#fff' }} align="right">
                                        Calif.
                                    </TableCell>
                                    <TableCell sx={{ color: '#fff' }} align="left">
                                        Fecha
                                    </TableCell>
                                    <TableCell sx={{ color: '#fff' }} align="left">
                                        Ver
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {intentos.map((intento, index) => (
                                    <TableRow key={`inetentos${index}`}>
                                        <TableCell sx={{ color: intento?.estatus == 1 ? '#4caf50' : '#fff' }} component="th" scope="row">
                                            <h5>{intentos.length - index}</h5>
                                        </TableCell>
                                        <TableCell sx={{ color: intento?.estatus == 1 ? '#4caf50' : '#fff' }} align="right">
                                            <h5>{intento?.calificacion}</h5>
                                        </TableCell>
                                        <TableCell sx={{ color: intento?.estatus == 1 ? '#4caf50' : '#fff' }} align="right">
                                            <h5>{intento?.fechaCreacion}</h5>
                                        </TableCell>
                                        <TableCell sx={{ color: intento?.estatus == 1 ? '#4caf50' : '#fff' }} align="right">
                                            <Link href="#" onClick={() => handleinput(intentos[index])}>
                                                Examen
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Scrollbars>
                    </Table>
                </div>
            ),
            icon: (
                <Typography variant="cardTitle" style={{ marginRight: '20px' }}>
                    {intentos.length}
                </Typography>
            ),
            click: () => (e) => () => {},
            disabled: false
        },
        {
            label: (
                <div>
                    <Typography sx={{ width: '100%', color: '#fff' }} align="center" variant="cardTitle">
                        Restaurar examen
                    </Typography>
                    <Table aria-label="simple table">
                        <Scrollbars style={{ height: 250, width: 300 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: '#fff' }}>#</TableCell>
                                    <TableCell sx={{ color: '#fff' }} align="right">
                                        Restaurado por
                                    </TableCell>
                                    <TableCell sx={{ color: '#fff' }} align="left">
                                        Fecha
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {restaurados.map((intento, index) => {
                                    if (!intento?.userRestaurador) {
                                        return null;
                                    }
                                    return (
                                        <TableRow key={`inetentos${index}`}>
                                            <TableCell sx={{ color: index == 1 ? '#4caf50' : '#fff' }} component="th" scope="row">
                                                <h5>{restaurados.length - index}</h5>
                                            </TableCell>
                                            <TableCell sx={{ color: index == 1 ? '#4caf50' : '#fff' }} align="right">
                                                <h5>{intento?.userRestaurador}</h5>
                                            </TableCell>
                                            <TableCell sx={{ color: index == 1 ? '#4caf50' : '#fff' }} align="right">
                                                <h5>{intento?.fechaRestaura}</h5>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Scrollbars>
                    </Table>
                </div>
                // <div style={{ marginLeft: '20px' }}>
                //     <Typography color="inherit" variant="cardBody">
                //         Restaurar examen
                //     </Typography>
                //     <br />
                //     <Typography color="inherit" variant="cardSubTitle">
                //         Ultima restauración por: {intentos[1]?.userRestaurador ? intentos[1]?.userRestaurador : '-'}
                //     </Typography>
                //     <br />
                //     <Typography color="inherit" variant="cardSubTitle">
                //         Fecha de restauración: {intentos[1]?.fechaRestaura ? intentos[1]?.fechaRestaura : '-'}
                //     </Typography>
                // </div>
            ),
            icon: <RestorePage />,
            click: () => (e) => restaurarExamen(),
            disabled: false
        }
    ];

    const buscaActividades = (sesion) => {
        let arrTem = sesion.temario;
        let arrAct = [];
        arrTem.forEach((element) => {
            if (element.activadad) {
                element.activadad.forEach((act) => {
                    arrAct.push(act);
                });
            }
        });
        return arrAct;
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={esVistaTrainer ? 12 : 6}>
                    <List className={classes.tree}>
                        {calificaciones.map(
                            (
                                curso //CURSO
                            ) => (
                                <React.Fragment key={curso.idCurso}>
                                    <ListItemButton id="btn-curso" onClick={cerrarExamen('curso', curso.idCurso)}>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            //onClick={cambiarOpenP()}
                                        >
                                            {curso.idCurso == open ? <ExpandMore /> : <ChevronRight />}
                                        </IconButton>
                                        <ListItemText primary={curso.titulo} />
                                        <Tooltip>
                                            <p>{curso?.calificacion}</p>
                                        </Tooltip>
                                    </ListItemButton>
                                    <Collapse in={curso.idCurso == open} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {curso.sesiones.map(
                                                (
                                                    sesion // sesion
                                                ) => (
                                                    <React.Fragment key={'sesion' + sesion.idSesion}>
                                                        <ListItemButton
                                                            id="btn-sesion"
                                                            button
                                                            className={classes.nestedCC}
                                                            onClick={cerrarExamen('sesion', 'sesion' + sesion.idSesion)}
                                                        >
                                                            {sesion.idtipoCentroCostos != '1' ? (
                                                                <IconButton
                                                                    aria-label="more"
                                                                    aria-controls="long-menu"
                                                                    aria-haspopup="true"
                                                                    // onClick={cambiarOpenCc()}
                                                                >
                                                                    {'sesion' + sesion.idSesion == openCc ? (
                                                                        <ExpandMore />
                                                                    ) : (
                                                                        <ChevronRight />
                                                                    )}
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton />
                                                            )}
                                                            <ListItemText primary={sesion.titulo} />
                                                            <Tooltip>
                                                                <p>{sesion?.calificacion}</p>
                                                            </Tooltip>
                                                        </ListItemButton>
                                                        <Collapse in={'sesion' + sesion.idSesion == openCc} timeout="auto" unmountOnExit>
                                                            {Number(curso.tipoCurso) == 1 ? (
                                                                <List component="div" disablePadding>
                                                                    {sesion.temario.map(
                                                                        (
                                                                            tema //TEMA
                                                                        ) => (
                                                                            <React.Fragment key={'temario' + tema.idTemario}>
                                                                                <ListItemButton
                                                                                    id="btn-tem"
                                                                                    button
                                                                                    className={classes.nestedEC}
                                                                                    onClick={cerrarExamen(
                                                                                        'temario',
                                                                                        'temario' + tema.idTemario
                                                                                    )}
                                                                                >
                                                                                    <IconButton
                                                                                        aria-label="more"
                                                                                        aria-controls="long-menu"
                                                                                        aria-haspopup="true"
                                                                                        // onClick={cambiarOpenEC()}
                                                                                    >
                                                                                        {'temario' + tema.idTemario == openEC ? (
                                                                                            <ExpandMore />
                                                                                        ) : (
                                                                                            <ChevronRight />
                                                                                        )}
                                                                                    </IconButton>
                                                                                    <ListItemText primary={tema.titulo} />
                                                                                    <Tooltip>
                                                                                        <p>{tema?.calificacion}</p>
                                                                                    </Tooltip>
                                                                                </ListItemButton>

                                                                                <Collapse
                                                                                    in={'temario' + tema.idTemario == openEC}
                                                                                    timeout="auto"
                                                                                    unmountOnExit
                                                                                >
                                                                                    <List component="div" disablePadding>
                                                                                        {tema.activadad.map(
                                                                                            (
                                                                                                actividad //actividad
                                                                                            ) => (
                                                                                                <React.Fragment
                                                                                                    key={
                                                                                                        'actividad' + actividad.idActividad
                                                                                                    }
                                                                                                >
                                                                                                    <ListItemButton
                                                                                                        id="btn-act"
                                                                                                        button
                                                                                                        className={classes.nestedAc}
                                                                                                        onClick={cerrarExamen(
                                                                                                            'actividad',
                                                                                                            'actividad' +
                                                                                                                actividad.idActividad
                                                                                                        )}
                                                                                                    >
                                                                                                        <IconButton
                                                                                                            aria-label="more"
                                                                                                            aria-controls="long-menu"
                                                                                                            aria-haspopup="true"
                                                                                                            // onClick={cambiarOpenAc(

                                                                                                            // )}
                                                                                                        >
                                                                                                            {'actividad' +
                                                                                                                actividad.idActividad ==
                                                                                                            openAc ? (
                                                                                                                <ExpandMore />
                                                                                                            ) : (
                                                                                                                <ChevronRight />
                                                                                                            )}
                                                                                                        </IconButton>
                                                                                                        <ListItemText
                                                                                                            primary={actividad.titulo}
                                                                                                        />
                                                                                                        <Tooltip>
                                                                                                            <p>{actividad?.calificacion}</p>
                                                                                                        </Tooltip>
                                                                                                    </ListItemButton>
                                                                                                    <Collapse
                                                                                                        in={
                                                                                                            'actividad' +
                                                                                                                actividad.idActividad ==
                                                                                                            openAc
                                                                                                        }
                                                                                                        timeout="auto"
                                                                                                        unmountOnExit
                                                                                                    >
                                                                                                        <List
                                                                                                            component="div"
                                                                                                            disablePadding
                                                                                                        >
                                                                                                            {actividad.detalleActividad.map(
                                                                                                                (
                                                                                                                    detalleActividad //detalle actividad
                                                                                                                ) => (
                                                                                                                    <React.Fragment
                                                                                                                        key={
                                                                                                                            'detalleActividad' +
                                                                                                                            detalleActividad.idDetalleActividad
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <ListItemButton
                                                                                                                            id="btn-exa"
                                                                                                                            button
                                                                                                                            className={
                                                                                                                                classes.nestedAcTed
                                                                                                                            }
                                                                                                                            onClick={
                                                                                                                                esVistaTrainer
                                                                                                                                    ? () => {}
                                                                                                                                    : cerrarExamen(
                                                                                                                                          'examen',
                                                                                                                                          detalleActividad
                                                                                                                                      )
                                                                                                                                // abrirExamen(
                                                                                                                                //       detalleActividad
                                                                                                                                //   )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {' '}
                                                                                                                            <ListItemText
                                                                                                                                primary={
                                                                                                                                    detalleActividad.titulo
                                                                                                                                }
                                                                                                                            />
                                                                                                                            {esVistaTrainer && (
                                                                                                                                <Tooltip title="Descargar material entregado">
                                                                                                                                    <Button
                                                                                                                                        id="btn-evid"
                                                                                                                                        component="label"
                                                                                                                                        role={
                                                                                                                                            undefined
                                                                                                                                        }
                                                                                                                                        variant="contained"
                                                                                                                                        tabIndex={
                                                                                                                                            -1
                                                                                                                                        }
                                                                                                                                        startIcon={
                                                                                                                                            <CloudDownload />
                                                                                                                                        }
                                                                                                                                        color="primary"
                                                                                                                                        onClick={() =>
                                                                                                                                            getRespuestaActividad(
                                                                                                                                                detalleActividad
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        Ver
                                                                                                                                        archivo
                                                                                                                                        evidencia
                                                                                                                                    </Button>
                                                                                                                                </Tooltip>
                                                                                                                            )}
                                                                                                                        </ListItemButton>
                                                                                                                    </React.Fragment>
                                                                                                                )
                                                                                                            )}
                                                                                                        </List>
                                                                                                    </Collapse>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        )}
                                                                                    </List>
                                                                                </Collapse>
                                                                            </React.Fragment>
                                                                        )
                                                                    )}
                                                                </List>
                                                            ) : (
                                                                <List component="div" disablePadding>
                                                                    {buscaActividades(sesion).map(
                                                                        (
                                                                            actividad //actividad
                                                                        ) => (
                                                                            <React.Fragment key={'actiSes' + actividad.idActividad}>
                                                                                <ListItemButton
                                                                                    id="btn-act"
                                                                                    button
                                                                                    className={classes.nestedAc}
                                                                                    onClick={cerrarExamen(
                                                                                        'actividad',
                                                                                        'actividad' + actividad.idActividad
                                                                                    )}
                                                                                >
                                                                                    <IconButton
                                                                                        aria-label="more"
                                                                                        aria-controls="long-menu"
                                                                                        aria-haspopup="true"
                                                                                    >
                                                                                        {'actividad' + actividad.idActividad == openAc ? (
                                                                                            <ExpandMore />
                                                                                        ) : (
                                                                                            <ChevronRight />
                                                                                        )}
                                                                                    </IconButton>
                                                                                    <ListItemText primary={actividad.titulo} />
                                                                                    <Tooltip>
                                                                                        <p>{actividad?.calificacion}</p>
                                                                                    </Tooltip>
                                                                                </ListItemButton>
                                                                                <Collapse
                                                                                    in={'actividad' + actividad.idActividad == openAc}
                                                                                    timeout="auto"
                                                                                    unmountOnExit
                                                                                >
                                                                                    <List component="div" disablePadding>
                                                                                        {actividad.detalleActividad.map(
                                                                                            (
                                                                                                detalleActividad //detalle actividad
                                                                                            ) => (
                                                                                                <React.Fragment
                                                                                                    key={
                                                                                                        'detAcSes' +
                                                                                                        detalleActividad.idDetalleActividad
                                                                                                    }
                                                                                                >
                                                                                                    <ListItemButton
                                                                                                        id="btn-exa"
                                                                                                        button
                                                                                                        className={classes.nestedAcTed}
                                                                                                        onClick={
                                                                                                            esVistaTrainer
                                                                                                                ? () => {}
                                                                                                                : cerrarExamen(
                                                                                                                      'examen',
                                                                                                                      detalleActividad
                                                                                                                  )
                                                                                                            // abrirExamen(
                                                                                                            //       detalleActividad
                                                                                                            //   )
                                                                                                        }
                                                                                                    >
                                                                                                        {' '}
                                                                                                        <ListItemText
                                                                                                            primary={
                                                                                                                detalleActividad.titulo
                                                                                                            }
                                                                                                        />
                                                                                                        {esVistaTrainer && (
                                                                                                            <Tooltip title="Descargar material entregado">
                                                                                                                <Button
                                                                                                                    id="btn-evid"
                                                                                                                    component="label"
                                                                                                                    role={undefined}
                                                                                                                    variant="contained"
                                                                                                                    tabIndex={-1}
                                                                                                                    startIcon={
                                                                                                                        <CloudDownload />
                                                                                                                    }
                                                                                                                    color="primary"
                                                                                                                    onClick={() =>
                                                                                                                        getRespuestaActividad(
                                                                                                                            detalleActividad
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    Ver archivo evidencia
                                                                                                                </Button>
                                                                                                            </Tooltip>
                                                                                                        )}
                                                                                                    </ListItemButton>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        )}
                                                                                    </List>
                                                                                </Collapse>
                                                                            </React.Fragment>
                                                                        )
                                                                    )}
                                                                </List>
                                                            )}
                                                        </Collapse>
                                                    </React.Fragment>
                                                )
                                            )}
                                        </List>
                                    </Collapse>
                                </React.Fragment>
                            )
                        )}
                    </List>
                </Grid>
                {!esVistaTrainer && (
                    <Grid item xs={6}>
                        <Collapse in={!examenSeleccionado?.preguntas}>
                            <Typography align="center" variant="subtitle1">
                                Seleccione un examén
                            </Typography>
                            <Typography align="center" variant="subtitle2">
                                {intentos.length >= 1 &&
                                    `Ultima restauración por: ${intentos[0]?.userRestaurador ? intentos[0]?.userRestaurador : '-'}`}
                            </Typography>
                            <Typography align="center" variant="subtitle2">
                                {intentos.length >= 1 &&
                                    `Fecha de restauración: ${intentos[0]?.fechaRestaura ? intentos[0]?.fechaRestaura : '-'}`}
                            </Typography>
                        </Collapse>
                        {/* <Collapse in={examenSeleccionado?.preguntas}> */}
                        <EstructuraExamen
                            rows={examenSeleccionado?.preguntas || []}
                            columns={columns}
                            accionesTabla={acciones}
                            accionesFila={[]}
                            columnKey="idPregunta"
                            // row={row}
                        />
                        {/* <Tabla
                            rows={examenSeleccionado?.preguntas || []}
                            columns={columns}
                            accionesTabla={acciones}
                            accionesFila={[]}
                            columnKey="idPregunta"
                            DetailByrow={(row) => (
                                <Grid container spacing={2}>
                                    {row?.respuestas?.map((rsp) => (
                                        <Grid item xs={3} key={rsp.nrespuesta}>
                                            <Item
                                                className={clsx(
                                                    rsp.isCorrecta == '1' ? classes.respuestaCorrecta : null,
                                                    rsp.idRespuestaUsuario != null ? classes.respuestaSeleccionada : null
                                                )}
                                                color="purple"
                                            >
                                                {row.idTipoPregunta == '1' ? `R- ${rsp.nrespuesta}` : `R- ${rsp.comentario}`}
                                            </Item>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        /> */}
                        {/* </Collapse> */}
                    </Grid>
                )}
                {esVistaTrainer && calificaciones?.length == 0 && (
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                            No se encuentra material entregado
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Snackbar
                open={mensaje.open}
                autoHideDuration={4000}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={cerrarMensaje}
            >
                <Alert
                    onClose={cerrarMensaje}
                    variant="filled"
                    severity={(mensaje.status < 0 && 'error') || (mensaje.status > 0 && 'success') || 'info'}
                >
                    {mensaje.value}
                </Alert>
            </Snackbar>
            {/* <ModalFormulario
                open={modalNotifi.open}
                width="sm"
                titulo="Resultados de Examen"
                aceptar={modalNotifi.aceptar}
                OnCancelar={cerrarModalNotifi}
            >
                {modalNotifi.Formulario && (
                    <modalNotifi.Formulario open={modalNotifi.open} defaultItem={modalNotifi.evidencia || {}} {...modalNotifi.props} />
                )}
                {modalNotifi.msg ? (
                    <Typography variant="subtitle1" gutterBottom>
                        {modalNotifi.msg}
                    </Typography>
                ) : null}
            </ModalFormulario> */}
        </div>
    );
}
