import React, { useRef, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Cancel, Edit, Refresh, PlayArrow, Pause } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    inputFile: {
        display: 'none'
    },
    videoContainer: {
        position: 'relative',
        width: '100%',
        '& video': {
            width: '100%',
            borderRadius: theme.shape.borderRadius
        },
        '& .hidden-video-controls': {
            display: 'none',
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            zIndex: 99,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
        },
        '&:hover .hidden-video-controls': {
            display: 'flex'
        }
    },
    videoPlaceholder: {
        width: '100%',
        height: '200px',
        backgroundColor: theme.palette.grey[200],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.shape.borderRadius,
        textAlign: 'center',
        color: theme.palette.text.secondary
    }
}));

const FormInputVideo = ({ control, name, label, defaultValue }) => {
    const classes = useStyles();
    const refInputFile = useRef(null);
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleVideoChange = (setValue, previousValue, file) => {
        if (previousValue?.objectURL && typeof previousValue?.objectURL === 'string') {
            URL.revokeObjectURL(previousValue.objectURL);
        }

        if (file) {
            if (file.type.includes('video/')) {
                setValue({ objectURL: URL.createObjectURL(file), file });
            } else {
                alert('Formato no válido. Seleccione un archivo de video.');
            }
        } else {
            alert('No se ha seleccionado un video.');
        }
    };

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={{ objectURL: defaultValue, file: null }}
            render={({ field }) => (
                <>
                    <div className={classes.videoContainer}>
                        {field.value.objectURL || defaultValue ? (
                            <video
                                ref={videoRef}
                                controls
                                src={field.value.objectURL || defaultValue}
                                onPause={() => setIsPlaying(false)}
                                onPlay={() => setIsPlaying(true)}
                            >
                                <track src="captions_en.vtt" kind="captions" srclang="en" label="english_captions" />
                                <track src="captions_es.vtt" kind="captions" srclang="es" label="spanish_captions" />
                                Tu navegador no soporta la reproducción de video.
                            </video>
                        ) : (
                            <div className={classes.videoPlaceholder}>
                                <Typography>{label || 'No hay video seleccionado'}</Typography>
                            </div>
                        )}
                        <div className="hidden-video-controls">
                            <Button
                                color="error"
                                onClick={() => field.onChange({ objectURL: defaultValue || null, file: null })}
                                variant="contained"
                            >
                                <Refresh />
                            </Button>
                            <Button color="primary" onClick={() => refInputFile.current.click()} variant="contained">
                                <Edit />
                            </Button>
                            <Button color="secondary" onClick={togglePlayPause} variant="contained">
                                {isPlaying ? <Pause /> : <PlayArrow />}
                            </Button>
                        </div>
                    </div>
                    <input
                        ref={refInputFile}
                        accept="video/*"
                        className={classes.inputFile}
                        type="file"
                        onChange={(e) => handleVideoChange(field.onChange, field.value, e.target.files[0] || null)}
                        onClick={(event) => {
                            event.target.value = null;
                        }}
                    />
                </>
            )}
        />
    );
};

export default FormInputVideo;
