import { Grid, TextField, FormControlLabel, Radio, Tabs, Tab } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FormInputImage, FormInputVideo, GroupRadioButtonForm, TextFieldNumber } from 'ui-component';
import { makeStyles } from '@mui/styles';
import { useFormContext } from 'react-hook-form';
import portadaGenerica from 'assets/images/PortadaGenerica.png';
import { AutocompleteForm } from 'ui-component';
import ServiciosCursosTrainers from './services';

const useStyles = makeStyles((theme) => ({
    inputFile: {
        display: 'none'
    },

    avatar: {
        border: `4px solid ${theme.palette.white}`,
        height: theme.spacing(20),
        width: '100%',
        position: 'relative'
    },

    div_foto: {
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        color: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        '& .hidden-avatar': {
            display: 'none',
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.50)',
            color: 'white',
            zIndex: '99',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: '4px solid white',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '48px'
        },
        '&:hover .hidden-avatar': {
            display: 'flex'
        }
    }
}));

const FormCursoTainer = ({ data, areas, clasificacionCurso }) => {
    const services = ServiciosCursosTrainers();
    const [select, setSelect] = useState(0);
    const arrImagen = ['jpg', 'jpeg', 'png', 'gif'];
    const arrVideo = ['mp4', 'wmv', 'avi', 'mkv'];

    const [ctlg, setCtlg] = useState({
        personal: [],
        areas: [],
        departamentos: [],
        puestos: [],
        personalFiltrados: [],
        areasFiltradas: [],
        puestosFiltrados: [],
        day: -1
    });
    const [areasFiltradas, setAreas] = useState([]);
    const [puestosFiltrados, setPuestos] = useState([]);
    const {
        register,
        watch,
        setValue,
        getValues,
        formState: { errors },
        control
    } = useFormContext();
    //console.log(watch());

    const cambioValor = (tipo) => (value) => {
        switch (tipo) {
            case 1:
                watch('departamentos', value);
                setValue('areas', null);
                setValue('puestos', null);
                if (value?.iddepto) {
                    let iddepto = value.iddepto;
                    setAreas(ctlg.areas.filter((e) => e.iddepto == iddepto));
                }
                // else {
                //     setCtlg({ ...ctlg, personalFiltrados: ctlg?.personal });
                // }
                break;
            case 2:
                setValue('puestos', null);
                if (value?.idarea) {
                    let iddepto = value.iddepto;
                    let idarea = value.idarea;
                    setPuestos(ctlg.puestos.filter((e) => e.iddepto == iddepto && e.idarea == idarea));
                }
                break;
            default:
                break;
        }
    };

    const clickCambioTipo = (event, value) => {
        setSelect(value);
    };

    useEffect(() => {
        if (data && ctlg?.departamentos.length > 0 && ctlg?.areas.length > 0 && ctlg?.puestos.length > 0) {
            let areas = ctlg.areas;
            let puestos = ctlg.puestos;

            let dep = ctlg.departamentos.find((item) => item.iddepto == data.depto);
            setValue('departamentos', dep);
            let iddepto = dep.iddepto;
            setAreas(areas.filter((e) => e.iddepto == iddepto));

            let ar = ctlg.areas.find((item) => item.idarea == data.area);
            setValue('areas', ar);
            let idarea = ar.idarea;
            setPuestos(puestos.filter((e) => e.iddepto == iddepto && e.idarea == idarea));
            let pu = ctlg.puestos.find((item) => item.idpuesto == data.puesto);
            setValue('puestos', pu);
        }
    }, [data, ctlg]);

    useEffect(() => {
        let idAreas = areas.map((area) => area.idarea);
        services.getInfoInt(
            (res) => {
                if (res?.status == 1) {
                    let personal = res?.data?.personal;
                    let areas = res?.data?.areas;
                    let departamentos = res?.data?.departamentos;
                    let puestos = res?.data?.puestos;
                    if (res.data && res.data.ruta) {
                        const arr = res.data.ruta.split('.');
                        const extension = arr[arr.length - 1];
                        if (arrImagen.includes(extension)) {
                            setSelect(0);
                        } else if (arrVideo.includes(extension)) {
                            setSelect(1);
                        }
                    }
                    if (res.data.portadaCurso) {
                        register('portadaCurso');
                        setValue('portadaCurso', { objectURL: res.data.portadaCurso, file: null });
                    }
                    setCtlg({
                        ...ctlg,
                        personal: personal,
                        personalFiltrados: personal,
                        departamentos: departamentos,
                        areas: areas,
                        areasFiltradas: areas,
                        puestos: puestos,
                        day: -1
                    });
                }
            },
            { areas: idAreas, idCurso: data?.idCurso || null }
        );
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Tabs
                    TabIndicatorProps={{ style: { backgroundColor: '#D97D54', color: '#D97D54' } }}
                    value={select}
                    onChange={clickCambioTipo}
                >
                    <Tab label="Imagen" value={0} />
                    <Tab label="Video" value={1} />
                </Tabs>
                {select == 0 && (
                    <FormInputImage
                        id="frm-inp-img"
                        control={control}
                        defaultValue={{ objeto: data?.portadaCurso ? getValues('portadaCurso.objectURL') : portadaGenerica }}
                        name="portadaCurso"
                        alt="cursoPortada"
                    />
                )}
                {select == 1 && (
                    <FormInputVideo
                        id="frm-inp-img"
                        defaultValue={data?.portadaCurso ? getValues('portadaCurso.objectURL') : null}
                        control={control}
                        name="portadaCurso"
                        alt="cursoPortada"
                    />
                )}
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="txt-tit"
                    label="Título *"
                    size="small"
                    fullWidth
                    {...register('titulo', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                    })}
                    error={Boolean(errors.titulo)}
                    helperText={errors.titulo?.message}
                    defaultValue={data?.titulo}
                />
            </Grid>
            <Grid item xs={4}>
                <AutocompleteForm
                    id="selectDepartamentos"
                    label="Departamento*"
                    name="departamentos"
                    control={control}
                    onChange={cambioValor(1)}
                    // onChange={cambioValor(1)}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    options={ctlg.departamentos}
                    optionSelected={{ label: 'ndepartamento', id: 'iddepto' }}
                    error={errors.departamentos?.message}
                />
            </Grid>

            <Grid item xs={4}>
                <AutocompleteForm
                    id="selectAreas"
                    label="Área*"
                    name="areas"
                    control={control}
                    onChange={cambioValor(2)}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    options={areasFiltradas}
                    optionSelected={{ label: 'narea', id: 'idarea' }}
                    error={errors.areas?.message}
                />
            </Grid>

            <Grid item xs={4}>
                <AutocompleteForm
                    id="selectPuestos"
                    label="Puesto*"
                    name="puestos"
                    control={control}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    options={puestosFiltrados}
                    optionSelected={{ label: 'npuesto', id: 'idpuesto' }}
                    error={errors.puestos?.message}
                />
            </Grid>
            {/* <Grid item xs={4}>
                <TextFieldNumber
                    id="txt-num-cal"
                    label="Calificación *"
                    name="califAprobatoria"
                    error={errors?.califAprobatoria?.message}
                    helperText={errors.califAprobatoria?.message}
                    defaultValue={data?.califAprobatoria}
                    control={control}
                    rules={{
                        required: { value: true, message: 'Campo requerido' },
                        validate: (value) => (Number(value) > 0 && Number(value) <= 10) || 'La cantidad no es correcta'
                    }}
                />
            </Grid> */}

            <Grid item xs={4}>
                <GroupRadioButtonForm
                    id="frm-btn-obg"
                    control={control}
                    name="obligatorio"
                    label="Obligatorio"
                    defaultValue={data?.obligatorio || '0'}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    error={errors?.obligatorio?.message}
                >
                    <FormControlLabel value="0" control={<Radio />} label="No" />
                    <FormControlLabel value="1" control={<Radio />} label="Sí" />
                </GroupRadioButtonForm>
            </Grid>
            <Grid item xs={12}>
                <AutocompleteForm
                    id="selectClasificacionCurso"
                    label="Clasificación*"
                    name="clasificacionCurso"
                    control={control}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    options={clasificacionCurso}
                    defaultValue={data?.clasificacion}
                    optionSelected={{ label: 'nClasificacioncurso', id: 'idClasificacionCurso' }}
                    error={errors?.clasificacionCurso?.message}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="txt-obj"
                    label="Objetivo *"
                    size="small"
                    fullWidth
                    {...register('objetivo', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                    })}
                    error={Boolean(errors.objetivo)}
                    helperText={errors.objetivo?.message}
                    defaultValue={data?.objetivo}
                />
            </Grid>
        </Grid>
    );
};

export default FormCursoTainer;
