import React, { useState, useEffect, useContext } from 'react';
import LayautContext from 'Context/ContextLayaut';
import FormCursoTainer from './FormCursoTrainer';
import { Grid, Typography } from '@mui/material';
import { AddBox } from '@mui/icons-material';
import { Tabla, AvisoConfirmacion } from 'ui-component';
import { makeStyles, styled } from '@mui/styles';
// import { ServiciosCurso } from '../../Cursos/components';
import ServiciosCursosTrainers from './services';

const CursosTrainers = ({ permisos, onCambioNivel, areas, ...props }) => {
    const [cursos, setCursos] = useState([]);
    const [modal, setModal] = useState({ open: false, titulo: '' });
    const [ctl, setCtl] = useState({ estatusCurso: [], clasificacionCurso: [] });
    const context = useContext(LayautContext);
    const cerrarModalCurso = () => setModal({ ...curso, open: false });
    const serviciosCursos = ServiciosCursosTrainers();

    const actualizarData = (nuevaCurso = []) => {
        if (nuevaCurso) {
            let cursoFormato = nuevaCurso.map((cursoObj) => {
                let areasCurso = cursoObj.areas?.split(',') || [];
                return {
                    ...cursoObj,
                    areas: ctl.areas?.filter((area) => areasCurso.includes(area.idarea))
                };
            });
            nuevaCurso = cursoFormato;
        }
        const datausuario = cursos.map((oc) => oc.idCurso);
        nuevaCurso.forEach((usuarioI) => {
            const indice = datausuario.indexOf(usuarioI.idCurso);
            if (indice >= 0) cursos.splice(indice, 1, usuarioI);
            else cursos.splice(0, 0, usuarioI);
        });
        setCursos([...cursos]);
    };

    const abrirNuevoCurso = () => () => {
        context.formulario.abrir({
            open: true,
            titulo: 'Nuevo curso',
            width: 'sm',
            formulario: FormCursoTainer,
            propsForm: { data: null, areas: areas, clasificacionCurso: ctl.clasificacionCurso },
            aceptar: (data) => {
                const dataFormAuxiliar = { ...data };
                dataFormAuxiliar.idCursoEstatus = 1;
                dataFormAuxiliar.areas = JSON.stringify(dataFormAuxiliar.areas);
                dataFormAuxiliar.departamentos = JSON.stringify(dataFormAuxiliar.departamentos);
                dataFormAuxiliar.puestos = JSON.stringify(dataFormAuxiliar.puestos);
                dataFormAuxiliar.clasificacionCurso = JSON.stringify(dataFormAuxiliar.clasificacionCurso);
                const formData = new FormData();
                Object.keys(dataFormAuxiliar).forEach((key) => {
                    if (key !== 'portadaCurso') formData.append(key, dataFormAuxiliar[key]);
                });
                formData.append('portadaCurso', dataFormAuxiliar.portadaCurso?.file || 0);
                if (dataFormAuxiliar?.portadaCurso?.size > 1900000) {
                    const nombreOriginal = dataFormAuxiliar.portadaCurso.path;
                    formData.delete('portadaCurso');
                    serviciosCursos.chunkFile(
                        (res, uidFolderName) => {
                            formData.append('portadaCurso', uidFolderName + '/' + nombreOriginal);
                            formData.append('uidFolderName', uidFolderName);
                            serviciosCursos.insertCursoTrainer(
                                (res) => {
                                    actualizarData(res.curso);
                                    context.formulario.cerrar();
                                },
                                formData,
                                { cargando: false, isBigFileResponse: true, uidFolderName: uidFolderName }
                            );
                        },
                        {},
                        dataFormAuxiliar.archivo
                    );
                    context.formulario.cerrar();
                } else {
                    serviciosCursos.insertCursoTrainer(
                        (res) => {
                            actualizarData(res.curso);
                            context.formulario.cerrar();
                        },
                        formData,
                        {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }
                    );
                }
            }
        });
    };

    const abrirEditarCurso = (curso) => {
        context.formulario.abrir({
            open: true,
            titulo: 'Editar curso',
            width: 'sm',
            formulario: FormCursoTainer,
            propsForm: {
                data: {
                    ...curso,
                    clasificacion: {
                        idClasificacionCurso: curso.idClasificacionCurso,
                        nClasificacioncurso: curso.nClasificacioncurso
                    }
                },
                areas: areas,
                clasificacionCurso: ctl.clasificacionCurso
            },
            aceptar: (data) => {
                data.idCurso = curso.idCurso;
                const dataFormAuxiliar = { ...data };
                dataFormAuxiliar.idCursoEstatus = 1;
                dataFormAuxiliar.areas = JSON.stringify(dataFormAuxiliar.areas);
                dataFormAuxiliar.departamentos = JSON.stringify(dataFormAuxiliar.departamentos);
                dataFormAuxiliar.puestos = JSON.stringify(dataFormAuxiliar.puestos);
                dataFormAuxiliar.clasificacionCurso = JSON.stringify(dataFormAuxiliar.clasificacionCurso);
                const formData = new FormData();
                Object.keys(dataFormAuxiliar).forEach((key) => {
                    if (key !== 'portadaCurso') formData.append(key, dataFormAuxiliar[key]);
                });
                // si existe portadaCurso file tomar ese valor o si no, si existe portadaCurso objectURL enviar "1", caso contrario enviar "0"
                formData.append('portadaCurso', dataFormAuxiliar.portadaCurso?.file || (dataFormAuxiliar.portadaCurso.objectURL ? 1 : 0));

                if (dataFormAuxiliar?.portadaCurso?.size > 1900000) {
                    const nombreOriginal = dataFormAuxiliar.portadaCurso.path;
                    formData.delete('portadaCurso');
                    serviciosCursos.chunkFile(
                        (res, uidFolderName) => {
                            formData.append('portadaCurso', uidFolderName + '/' + nombreOriginal);
                            formData.append('uidFolderName', uidFolderName);
                            serviciosCursos.updateCursoTrainer(
                                (res) => {
                                    actualizarData(res.curso);
                                    context.formulario.cerrar();
                                },
                                formData,
                                { cargando: false, isBigFileResponse: true, uidFolderName: uidFolderName }
                            );
                        },
                        {},
                        dataFormPivote.archivo
                    );
                    context.formulario.cerrar();
                } else {
                    serviciosCursos.updateCursoTrainer(
                        (res) => {
                            actualizarData(res.curso);
                            context.formulario.cerrar();
                        },
                        formData,
                        {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }
                    );
                }
            }
        });
    };
    const columnas = [
        { label: 'Título', name: 'titulo', filtrar: true },
        { label: 'Departamento', name: 'ndepartamento', filtrar: true },
        { label: 'Área', name: 'narea', filtrar: true },
        { label: 'Puesto', name: 'npuesto', filtrar: true },
        // { label: 'Calificación', name: 'califAprobatoria', filtrar: true },
        {
            label: 'Estatus',
            name: 'nCursoEstatus',
            filtrar: true
        }
    ];
    const acciones = [{ label: 'Nuevo Curso', icon: <AddBox />, click: abrirNuevoCurso, disabled: false }];
    const accionesFila = [
        //permisos.editar
        { label: 'Editar', onClick: abrirEditarCurso, enabled: true },
        {
            label: 'Sesiones',
            onClick: (cursoSeleccionado) => onCambioNivel(cursoSeleccionado, cursos),
            enabled: true
        }
    ];

    useEffect(() => {
        serviciosCursos.cursosInt((ct) => {
            if (ct.curso) {
                let cursoFormato = ct.curso?.map((cursoObj) => {
                    let areasCurso = cursoObj.areas?.split(',') || [];
                    return {
                        ...cursoObj,
                        areas: ct.areas?.filter((area) => areasCurso.includes(area.idarea))
                    };
                });
                if (!['1'].includes(props.usuario.idrol))
                    cursoFormato = cursoFormato.filter((curso) => {
                        let usuarioTieneArea = false;
                        let idAreasUsuario = props?.usuario?.areas?.map((area) => area.idarea) || [];
                        curso.areas.forEach((area) => {
                            if (idAreasUsuario.includes(area.idarea)) usuarioTieneArea = true;
                        });
                        return usuarioTieneArea;
                    });
                setCursos(cursoFormato || []);
            }
            setCtl({ ...ctl, estatusCurso: ct.estatusCurso || [], areas: ct.areas, clasificacionCurso: ct.clasificacionCurso || [] });
        });
    }, []);

    return (
        <>
            <Tabla
                rows={cursos}
                columns={columnas}
                accionesTabla={acciones}
                accionesFila={accionesFila}
                columnKey="idCurso"
                DetailByrow={(row) => (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                <b>Objetivo: </b>
                                {row.objetivo}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            />
            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
            {/* <ModalGeneral open={modal.open} width="lg" titulo={modal.titulo} OnCerrar={cerrarModalCurso}>
        <ModalCostosGrupo curso={modal.curso} />
    </ModalGeneral> */}
        </>
    );
};

export default CursosTrainers;
