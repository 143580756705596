import React, { useEffect, useState, useContext } from 'react';
import LayautContext from 'Context/ContextLayaut';
import LoginContext from 'Context/ContextLogin';
// material-ui
import PropTypes from 'prop-types';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import { Grid, Breadcrumbs, Chip, Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { NavigateNext, Print, Star } from '@mui/icons-material';
import { IconHome } from '@tabler/icons';

// project imports
import Services from 'utils/serviciosVentas';
import { gridSpacing } from 'store/constant';
import TemarioC from './ComponentsHomeVentas/TemarioC';

// Imports Project
import SubCardCollapase from 'ui-component/cards/SubCardCollapase';
import Ranking from '../Dash/Components/Ranking';
import Contacto from './ComponentsHomeVentas/Contacto';
import Actividad from './ComponentsHomeVentas/Actividad';
import MainCard from 'ui-component/cards/MainCard';
// ==============================|| DEFAULT DASHBOARD ||============================== //

const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        margin: '5px',
        padding: '0px'
    }
}))(Chip);

const useStyles = makeStyles((theme) => ({
    curso: {
        backgroundColor: theme.palette.secondary[800]
    },
    grupo: {
        borderRadius: '8px',
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary[800]
    },
    breadCrumbAsesor: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: '32px',
        background: theme.palette.background.default,
        zIndex: 2000
    },
    breadCrumbAdm: {
        width: '100%',
        height: '32px',
        background: theme.palette.background.default,
        zIndex: 2000
    }
}));

const Dashboard = ({ permisos, usuario, arbolCursos, setArbolCursos, ranking, curso, unselectCurso, info, option, esIncognito }) => {
    const services = Services();
    const theme = useTheme();
    const classes = useStyles();
    const context = useContext(LayautContext);
    const contextUser = useContext(LoginContext);

    const [infoActividad, setInfoActividad] = useState({
        show: false,
        tipo: 0,
        lectura: {},
        video: {},
        examen: [],
        showResultado: false,
        onlyShow: false
    });
    const [breadcrumbs, setBreadcrumbs] = useState('');
    const [ctl, setCtl] = useState({
        grupos: [],
        cursos: [],
        cursoActivo: { sesiones: [] },
        sesion: [],
        temario: [],
        calificacion: 0,
        aciertos: 0,
        desaciertos: 0,
        actividades: []
    });
    const [openP, setOpenP] = useState(null);
    const [openS, setOpenS] = useState(null);
    const [openT, setOpenT] = useState(null);
    const [openA, setOpenA] = useState(null);

    const [select, setSelect] = useState('0');

    const cambiarOpenP = (id) => {
        if (id === openP) setOpenP(null);
        else setOpenP(id);
    };
    const cambiarOpenS = (id) => {
        if (id === openS) setOpenS(null);
        else setOpenS(id);
    };
    const cambiarOpenT = (id) => {
        if (id === openT) setOpenT(null);
        else setOpenT(id);
    };
    const cambiarOpenA = (id) => {
        if (id === openA) setOpenA(null);
        else setOpenA(id);
    };

    const arrBradcrumbs = breadcrumbs?.split('|,');
    const collapse = [cambiarOpenP, cambiarOpenS, cambiarOpenT, cambiarOpenA];
    const prefix = ['curso', 'sesion', 'temario', 'actividades'];

    // Servicios
    const onclickActividad = (detalleActividad) => {
        const props = {
            ...detalleActividad,
            show: true,
            showResultado: false,
            onlyShow: false
            // tipo: detalleActividad.tipo,
            // titulo: detalleActividad.titulo,
            // idColaborador: usuario.idColaborador,
            // idDetalleActividad: detalleActividad.idDetalleActividad,
            // idActividad: detalleActividad.idActividad,
            // idTemario: detalleActividad.idTemario,
            // idSesion: detalleActividad.idSesion,
            // idCurso: detalleActividad.idCurso,
            // califAprobatoria: detalleActividad.califAprobatoria,
            // idCalificacionDetalle: detalleActividad.idCalificacionDetalle
        };

        // si queremos incluir la opcion de marcar video como finalizados hay que incluir el tipo de actividad aqui
        if (detalleActividad.idCalificacionDetalle && (detalleActividad.tipo == 3 || detalleActividad.tipo == 7)) {
            const nData = {
                ...infoActividad,
                ...props,
                showResultado: true
                // calificacion: detalleActividad.calificacion,
                // aciertos: detalleActividad.aciertos,
                // desaciertos: detalleActividad.desaciertos
            };

            setInfoActividad(nData);
            return true;
        }
        services.getInfoActividad(
            (res) => {
                let objData = res.data;

                if (objData && [3, 7].includes(Number(objData.infoGeneral.idTipoActividad))) {
                    if (contextUser?.login?.usuario?.fecha_ingreso && objData.infoGeneral.diasHabilitar != null) {
                        const fechaUsuario = new Date(contextUser?.login?.usuario?.fecha_ingreso);
                        const fechaActual = new Date();

                        const timeDifference = Math.abs(fechaActual.getTime() - fechaUsuario.getTime());
                        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

                        if (daysDifference < Number(objData.infoGeneral.diasHabilitar)) {
                            objData.infoGeneral.bloqueoDias = true;
                            objData.infoGeneral.numDiasBloqueado = Number(objData.infoGeneral.diasHabilitar) - daysDifference;
                        }
                    }
                }
                setInfoActividad({ ...infoActividad, ...props, ...objData });
            },
            {
                idDetalleActividad: detalleActividad.idDetalleActividad,
                tipo: detalleActividad.tipo
            }
        );
        return true;
    };

    const actividadByDefault = () => {
        const infoDef = { idColaborador: usuario.idColaborador };
        const sesion = curso.sesiones.filter(
            (ses) => ses.porcentajeCompletado < 100 || ses.isAprobado == 0 || ses.isAprobado == undefined
        )[0];
        const temario = sesion?.temarios.filter(
            (tem) => tem.porcentajeCompletado < 100 || tem.isAprobado == 0 || tem.isAprobado == undefined
        )[0];
        const actividad = temario?.actividades.filter(
            (act) => act.porcentajeCompletado < 100 || act.isAprobado == 0 || act.isAprobado == undefined
        )[0];
        const detalle = actividad?.detalleActividades.filter((det) => !det.idCalificacionDetalle || det.isAprobado == 0)[0];

        if (sesion == undefined || temario == undefined || actividad == undefined || detalle == undefined) {
            setInfoActividad(infoDef);
            return true;
        }

        const actividadActual = {
            idCurso: curso.idCurso,
            idSesion: sesion.idSesion,
            idTemario: temario.idTemario,
            idActividad: actividad.idActividad,
            ...detalle
        };

        cambiarOpenS(`sesion${sesion.idSesion}`);
        cambiarOpenT(`temario${temario.idTemario}`);
        cambiarOpenA(`actividades${actividad.idActividad}`);
        setBreadcrumbs(actividadActual.breadcrumb);

        if (!actividadActual?.idDetalleActividad) {
            setInfoActividad(infoDef);
            return false;
        }

        const props = {
            ...infoDef,
            show: true,
            showResultado: false,
            onlyShow: false,
            califAprobatoria: curso.califAprobatoria
        };

        if (actividadActual?.idCalificacionDetalle) {
            const nData = {
                ...infoActividad,
                ...props,
                ...actividadActual,
                showResultado: true,
                calificacion: actividadActual.calificacion,
                aciertos: actividadActual.aciertos,
                desaciertos: actividadActual.desaciertos
            };

            setInfoActividad(nData);
            return true;
        }

        services.getInfoActividad(
            (res) => {
                let objData = res.data;

                if (objData && [3, 7].includes(Number(objData.infoGeneral.idTipoActividad))) {
                    if (contextUser?.login?.usuario?.fecha_ingreso && objData.infoGeneral.diasHabilitar != null) {
                        const fechaUsuario = new Date(contextUser?.login?.usuario?.fecha_ingreso);
                        const fechaActual = new Date();

                        const timeDifference = Math.abs(fechaActual.getTime() - fechaUsuario.getTime());
                        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

                        if (daysDifference < Number(objData.infoGeneral.diasHabilitar)) {
                            objData.infoGeneral.bloqueoDias = true;
                            objData.infoGeneral.numDiasBloqueado = Number(objData.infoGeneral.diasHabilitar) - daysDifference;
                        }
                    }
                }
                setInfoActividad({
                    ...infoActividad,
                    ...actividadActual,
                    tipo: actividadActual.tipo,
                    ...props,
                    ...objData
                });
            },
            {
                idDetalleActividad: actividadActual.idDetalleActividad,
                tipo: actividadActual.tipo
            }
        );

        return true;
    };

    const onClickFinalizarActividad = (data) => {
        services.sendRespuestas(
            (res) => {
                setInfoActividad({
                    ...infoActividad,
                    showResultado: true,
                    calificacion: res.calificacion,
                    aciertos: res.aciertos,
                    desaciertos: res.desaciertos,
                    isAprobado: res.isAprobado,
                    nintentos: res.nintentos,
                    idCalificacionDetalle: res?.idCalificacionDetalle
                });
                setArbolCursos({ ...arbolCursos, cursos: res.cursos });
                setCtl({
                    ...ctl,
                    cursos: res.cursos,
                    cursoActivo: res.cursos?.find((crs) => crs.idCurso == ctl.cursoActivo.idCurso)
                });
            },
            { ...data, option }
        );
    };

    const verActividadRespondida = (dataSend) => {
        // const propsActividad = { showResultado: false, show: true, onlyShow: true };
        // services.getInfoActividad((res) => setInfoActividad({ ...infoActividad, ...propsActividad, ...res.data }), dataSend);
    };

    const reiniciarExamen = (detalle) => {
        const propsActividad = { showResultado: false, show: false, onlyShow: false };
        let numIntentos = 0;

        if (Number(detalle.tipoCurso) == 2) {
            numIntentos =
                detalle.numIntentos && detalle.numIntentos != null
                    ? Number(detalle.numIntentos) - Number(detalle.nintentos)
                    : 3 - Number(detalle.nintentos);
        } else {
            numIntentos = 3 - Number(detalle.nintentos);
        }

        context.modal.confirmar({
            open: true,
            title: 'Reiniciar',
            text:
                Number(detalle.tipo) == 3
                    ? `Recuerda que tienes ${numIntentos} intentos más para aprobar el examen`
                    : 'Sus respuestas serán reemplazadas completamente',
            aceptar: async () => {
                await services.reiniciarExamen(
                    (res) => {
                        setArbolCursos({ ...arbolCursos, cursos: res.cursos });
                        setInfoActividad({ ...infoActividad, ...propsActividad, lectura: false, video: false, examen: res.examen });
                    },
                    {
                        idCurso: detalle.idCurso,
                        idDetalleActividad: detalle.idDetalleActividad,
                        idCalificacionDetalle: detalle.idCalificacionDetalle,
                        idColaborador: usuario.idColaborador,
                        option
                    }
                );
                context.modal.cerrar();
            }
        });
    };

    const regresar = () => unselectCurso();

    const clickCambioTipo = (event, value) => {
        // services.getAsesores(
        //     (res) => {
        //         setAsesores(res);
        //     },
        //     { option: value }
        // );
        setSelect(value);
    };

    const accionesFila = [{ label: 'Abrir detalle', onClick: () => {}, enabled: true }];
    useEffect(() => {
        actividadByDefault();
    }, []);
    useEffect(() => {
        if (arbolCursos.cursos.length > 0) setCtl({ ...ctl, cursoActivo: curso, cursos: arbolCursos.cursos });
        else setCtl({ ...ctl, cursoActivo: { sesiones: [] }, cursos: [] });
    }, [curso]);

    console.log('infoact', infoActividad);

    return (
        <MainCard title={<Typography variant="title">{ctl?.cursoActivo?.titulo}</Typography>}>
            <Grid container sx={{ p: 4 }} spacing={gridSpacing} alignItems="flex-start" justifyContent="flex-start">
                <Grid item xs={12} md={8}>
                    {infoActividad.show && (
                        <Actividad
                            cognito={esIncognito}
                            permisos={permisos}
                            data={{ ...infoActividad, idColaborador: usuario.idColaborador, ncurso: ctl?.cursoActivo?.titulo }}
                            // breadcrumbs={breadcrumbs}
                            onClickFinalizar={onClickFinalizarActividad}
                            reiniciarExamen={reiniciarExamen}
                            regresar={regresar}
                        />
                    )}
                </Grid>

                <Grid item xs={12} md={4} direction="column">
                    <Tabs sx={{ p: 0 }} value={select} onChange={clickCambioTipo} aria-label="basic tabs example">
                        <Tab label="Temario" value="0" />
                        <Tab label="Contactános" value="2" />
                    </Tabs>

                    {select == '0' && (
                        // <SubCardCollapase openCollapse title={curso.titulo}>

                        <TemarioC
                            services={services}
                            ctl={arbolCursos}
                            curso={ctl.cursoActivo}
                            setBreadcrumbs={setBreadcrumbs}
                            onclick={onclickActividad}
                            openP={openP}
                            openS={openS}
                            openT={openT}
                            openA={openA}
                            cambiarOpenP={cambiarOpenP}
                            cambiarOpenS={cambiarOpenS}
                            cambiarOpenT={cambiarOpenT}
                            cambiarOpenA={cambiarOpenA}
                        />
                    )}
                    {select == '1' && <Ranking colaboradores={ranking.topTen} />}
                    {select == '2' && <Contacto infoCotac={info} />}
                </Grid>

                <Grid item xs={12} md={12}>
                    <Breadcrumbs
                        className={permisos?.finExa ? classes.breadCrumbAsesor : classes.breadCrumbAdm}
                        separator={<NavigateNext fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        <StyledBreadcrumb label="Inicio" icon={<IconHome fontSize="small" />} onClick={() => regresar()} />
                        {arrBradcrumbs.map((bread, key) => {
                            const crumb = bread?.split('^~');
                            const id = crumb[0];
                            const titulo = crumb[1];

                            // Necesita los prefijos para saber el id seleccionado
                            return key < 4 ? (
                                <StyledBreadcrumb
                                    key={`${id}-${titulo}`}
                                    label={titulo}
                                    onClick={() => collapse[key](`${prefix[key]}${id}`)}
                                />
                            ) : (
                                <StyledBreadcrumb key={`${id}-${titulo}`} label={titulo} /* icon={<Star fontSize="small" />} */ />
                            );
                        })}
                    </Breadcrumbs>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default Dashboard;
