import { Chip, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CardCurso from '../HomeParticipantes/Components/Cards/CardCurso';
import Services from 'utils/serviciosVentas';
import MainCard from 'ui-component/cards/MainCard';

const CursosCompletados = ({ usuario, option }) => {
    const services = Services();

    const [cargando, setCargando] = useState({ open: false });
    const [hasCursos, setHasCursos] = useState(true);
    const [numCursos, setNum] = useState(0);
    const [arbolCursos, setArbolCursos] = useState({
        grupos: [],
        cursos: [],
        sesion: [],
        temario: [],
        calificacion: 0,
        aciertos: 0,
        desaciertos: 0,
        actividades: []
    });
    // Services
    const getArbolCursos = () => {
        setCargando({ open: true });
        services.Allcursos(
            (data) => {
                setArbolCursos({
                    ...arbolCursos,
                    cursos: data.cursos
                });
                if (data.cursos && data.cursos.length == 0) setHasCursos(false);
                setCargando({ open: false });
            },
            { idColaborador: usuario.idColaborador, idGrupo: usuario.idGrupo, option, idContrato: usuario.idcontrato }
        );
    };

    useEffect(() => {
        getArbolCursos();
        // getColaboradores();
    }, []);

    useEffect(() => {
        if (arbolCursos && arbolCursos.cursos.length > 0) {
            const arr = arbolCursos.cursos.filter((cur) => Number(cur.porcentajeCompletado) >= 100);

            if (arr && arr.length > 0) {
                setHasCursos(true);
                setNum(arr.length);
            } else {
                setHasCursos(false);
            }
        } else {
            setHasCursos(false);
        }
    }, [arbolCursos]);

    return (
        <MainCard title={<Typography variant="title">Cursos completados</Typography>}>
            <Grid container sx={{ border: 'solid #dddddd 5px', borderRadius: '15px', backgroundColor: '#f9f9f9' }} spacing={2}>
                <Grid item xs={12}>
                    {hasCursos && (
                        <Chip
                            style={{ backgroundColor: '#cabc93', color: '#FFFFFF' }}
                            label={`Cursos completados: ${numCursos}`}
                            size="medium"
                        />
                    )}
                </Grid>

                <Grid item xs={12}>
                    {hasCursos ? (
                        <Grid
                            container
                            spacing={1}
                            display="flex"
                            alignItems="center"
                            direction="row"
                            wrap="nowrap"
                            sx={{ overflowX: 'scroll' }}
                        >
                            {arbolCursos?.cursos
                                ?.filter((curso) => curso.isAprobado == 1)
                                .map((curso) => (
                                    <Grid item key={`${curso.titulo}${curso.idCurso}`}>
                                        <CardCurso curso={curso} esCompleto={true} />
                                    </Grid>
                                ))}
                        </Grid>
                    ) : (
                        <>
                            <br></br>
                            <Typography variant="h4" align="center" gutterBottom>
                                No has completado ningún curso
                            </Typography>
                        </>
                    )}
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default CursosCompletados;
