const columns = (cambioEstatus, ctl, permisos) => ({
    curso: [
        { label: 'Título', name: 'titulo', filtrar: true },
        { label: 'Subtítulo', name: 'subtitulo', filtrar: true },
        { label: 'Costo', name: 'costo', filtrar: true, type: { opcion: 'numero', accion: 'money' } },
        { label: 'Nivel requerido', name: 'nivelRequerido', filtrar: true },
        { label: 'Duración', name: 'duracionCurso', filtrar: true },
        {
            label: 'Interno',
            name: 'interno',
            filtrar: {
                id: 'idint',
                value: 'nint',
                ctl: [
                    { idint: '1', nint: 'Si' },
                    { idint: '2', nint: 'No' }
                ]
            }
        },
        {
            label: 'Extemporaneo',
            name: 'extemporaneo',
            filtrar: {
                id: 'idext',
                value: 'next',
                ctl: [
                    { idext: '1', next: 'Si' },
                    { idext: '2', next: 'No' }
                ]
            }
        },
        { label: 'Estatus', name: 'nCursoEstatus', filtrar: { id: 'idEstatus', value: 'nCursoEstatus', ctl: ctl.estatusCurso } }
    ],
    cursoTrainer: [
        { label: 'Departamento', name: 'departamento', filtrar: true },
        { label: 'Área', name: 'area', filtrar: true },
        { label: 'Puesto', name: 'puesto', filtrar: true },
        { label: 'Título', name: 'titulo', filtrar: true }
    ],
    sesion: [
        // { label: '#', name: 'idSesion' },
        { label: 'Orden', name: 'orden' },
        { label: 'Título', name: 'titulo' },
        { label: 'Duración', name: 'duracionSesion' },
        { label: 'Modificación', name: 'fechaModificacion' },
        { label: 'Usuario', name: 'nusuario' },
        permisos?.modificar
            ? {
                  label: 'Estatus',
                  name: 'estatus',
                  filtrar: false,
                  type: { opcion: 'switch', accion: cambioEstatus }
              }
            : { label: 'Estatus', name: 'estatus', type: (row) => (row.estatus == '1' ? 'Activo' : 'Inactivo') }
    ],
    temario: [
        { label: 'idTemario', name: 'idTemario' },
        { label: 'Orden', name: 'orden' },
        { label: 'título', name: 'titulo' },
        { label: 'Descripción', name: 'descripcion' },
        { label: 'Modificación', name: 'fechaModificacion' },
        { label: 'Usuario', name: 'nusuario' },
        permisos?.modificar
            ? {
                  label: 'Estatus',
                  name: 'estatus',
                  filtrar: false,
                  type: { opcion: 'switch', accion: cambioEstatus }
              }
            : { label: 'Estatus', name: 'estatus', type: (row) => (row.estatus == '1' ? 'Activo' : 'Inactivo') }
    ],
    actividad: [
        { label: 'Orden', name: 'orden' },
        { label: 'Título', name: 'titulo' },
        { label: 'Descripción', name: 'descripcion' },
        permisos?.modificar
            ? {
                  label: 'Estatus',
                  name: 'estatus',
                  filtrar: false,
                  type: { opcion: 'switch', accion: cambioEstatus }
              }
            : { label: 'Estatus', name: 'estatus', type: (row) => (row.estatus == '1' ? 'Activo' : 'Inactivo') }
    ],
    detalleActividad: [
        { label: 'Orden', name: 'orden' },
        { label: 'título', name: 'titulo' },
        { label: 'Tipo de actividad', name: 'ntipoActividad' },
        { label: 'fecha Modificación', name: 'fechaModificacion' },
        permisos?.modificar
            ? {
                  label: 'Estatus',
                  name: 'estatus',
                  filtrar: false,
                  type: { opcion: 'switch', accion: cambioEstatus }
              }
            : { label: 'Estatus', name: 'estatus', type: (row) => (row.estatus == '1' ? 'Activo' : 'Inactivo') }
    ],
    grupo: [
        { label: 'Grupo', name: 'nombre', filtrar: true },
        { label: 'Actividad', name: 'ntipoActividad' },
        { label: 'fecha Modificación', name: 'fechaModificacion' },
        permisos?.modificar
            ? {
                  label: 'Estatus',
                  name: 'estatus',
                  filtrar: false,
                  type: { opcion: 'switch', accion: cambioEstatus }
              }
            : { label: 'Estatus', name: 'estatus', type: (row) => (row.estatus == '1' ? 'Activo' : 'Inactivo') }
    ],
    asignarGrupoByCurso: [
        { label: 'Asignado', name: 'isAsignado', filtrar: true },
        { label: 'Grupo', name: 'nombre', filtrar: true },
        { label: 'fecha Modificación', name: 'fechaModificacion' },
        { label: 'Estatus', name: 'estatus', type: (row) => (row.estatus == '1' ? 'Activo' : 'Inactivo') }
    ]
});

export default columns;
