// assets
import { IconKey, IconUsers, IconBoxMultiple } from '@tabler/icons';
import { ReactComponent as IconHome } from 'assets/images/icons/ga-home.svg';
import { ReactComponent as IconCalificacion } from 'assets/images/icons/g-calificaciones.svg';
import { ReactComponent as IconDashboard } from 'assets/images/icons/g-dashboard.svg';
import { ReactComponent as IconEngrane } from 'assets/images/icons/engrane.svg';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RepeatOnIcon from '@mui/icons-material/RepeatOn';
import DoorSlidingIcon from '@mui/icons-material/DoorSliding';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import Diversity3 from '@mui/icons-material/Diversity3';

// constant
const icons = {
    tra: IconKey,
    traAdmin: Diversity3,
    ctl: IconBoxMultiple,
    homeIni: IconHome,
    clfClb: IconCalificacion,
    das: IconDashboard,
    config: IconEngrane,
    rep: AssignmentIcon,
    btnIncog: FaceRetouchingOffIcon,
    efe: QueryStatsIcon,
    dnc: RepeatOnIcon,
    intIni: DoorSlidingIcon,
    intEsp: DoorBackIcon,
    cursGene: AutoStoriesIcon,
    traiMade: ConnectWithoutContactIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = (rutas) => {
    const paginas = [];
    rutas.forEach((pagina) => {
        if (pagina.idPadre) {
            const indicePagina = paginas.map((pagina) => pagina.id).indexOf(pagina.idPadre);
            if (indicePagina >= 0)
                paginas[indicePagina].children?.push({
                    id: pagina.idpagina,
                    title: pagina.npagina,
                    type: 'item',
                    icon: icons[pagina.idpagina],
                    url: pagina.raiz === '0' ? pagina.rutaPadre + pagina.ruta : '/'
                });
            else
                paginas.push({
                    id: pagina.idPadre,
                    title: pagina.ncategoria,
                    type: 'collapse',
                    icon: icons[pagina.idPadre],
                    children: [
                        {
                            id: pagina.idpagina,
                            title: pagina.npagina,
                            type: 'item',
                            icon: icons[pagina.idpagina],
                            url: pagina.raiz === '0' ? pagina.rutaPadre + pagina.ruta : '/'
                        }
                    ]
                });
        } else
            paginas.push({
                id: pagina.idpagina,
                title: pagina.npagina,
                type: 'item',
                icon: icons[pagina.idpagina],
                url: pagina.raiz === '0' ? pagina.ruta : '/',
                breadcrumbs: true
            });
    });
    return {
        id: 'pages',
        title: 'MENÚ',
        caption: '',
        type: 'group',
        children: paginas
    };
};

export default pages;
